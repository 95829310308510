import React, { useEffect, useState, useRef } from "react";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";

import Header from "../components/Header/Head.js";
import Footer from "../components/Footer/Footer.js";
import Profile from "../assets/images/profile.png";
import Tokencard from "../components/seperate/tokencard.js";
import Load from "../assets/images/load_more.gif";
import { useSelector } from "react-redux";
import axios from "axios";
import { CollectiblesList_Home } from "../actions/v1/token";
import config from "../lib/config";
import isEmpty from "../lib/isEmpty";
import { Placeabid } from "../components/placeabid/placeabid";
import { LikeRef } from "../components/LikeRef";
import Loader from "./Loader.js";

export default function Explore() {
  const { Param_category } = useParams();

  var LikeForwardRef = useRef();
  const location = useLocation();
  const [CatName, setCatName] = useState("");
  const [typefunfix11, Settypefun] = useState("");
  const [newcollstate, Setnewcollstate] = useState({});
  const [CatBasedTokenList, setCatBasedTokenList] = useState({
    loader: false,
    All: { page: 1, list: [], onmore: true },
  });
  const [LoadDisable, Set_LoadDisable] = useState(false);
  const [loadImage, setloadImage] = useState(false);
  const [TokenList, setTokenList] = useState([]);
  const [Creatorcount, setCreatorcount] = useState(0);
  const [Categorylist, setCategorylist] = useState([]);
  const [item, Set_item] = useState({});
  const [LikedTokenList, setLikedTokenList] = useState([]);
  const [Bids, Set_Bids] = useState([]);
  const [BuyOwnerDetailFirst, Set_BuyOwnerDetailFirst] = useState({});
  const [tokenCounts_Detail, Set_tokenCounts_Detail] = useState({});
  const [MyTokenBalance, Set_MyTokenBalance] = useState(0);
  const [MyTokenDetail, Set_MyTokenDetail] = useState({});
  const [AllowedQuantity, Set_AllowedQuantity] = useState({});
  const [YouWillPay, Set_YouWillPay] = useState(0);
  const [YouWillPayFee, Set_YouWillPayFee] = useState(0);
  const [YouWillGet, Set_YouWillGet] = useState(0);
  const [convertVal, setConvertVal] = React.useState(0);
  const [load, setload] = useState(true);
  var PlaceABidForwardRef = useRef();
  const [MyItemAccountAddr, Set_MyItemAccountAddr] = useState("");
  const [tokenCounts, Set_tokenCounts] = useState(0);
  const [AccepBidSelect, Set_AccepBidSelect] = useState(0);
  const [tokenBidAmt, Set_tokenBidAmt] = useState(0);
  const [NoOfToken, Set_NoOfToken] = useState(1);
  const [ValidateError, Set_ValidateError] = useState({});
  const [TokenBalance, Set_TokenBalance] = useState(0);
  const [BidApply_ApproveCallStatus, Set_BidApply_ApproveCallStatus] = useState(
    "init"
  );
  const [BidApply_SignCallStatus, Set_BidApply_SignCallStatus] = useState(
    "init"
  );
  const [Service_Fee, set_Service_Fee] = useState(0);
  const [MyItemAccountAddr_Details, Set_MyItemAccountAddr_Details] = useState(
    {}
  );
  const [loading, setloading] = useState(false);
  const dashboardRoutes = [];
  const Wallet_Details = useSelector((state) => state.wallet_connect_context);
  useEffect(() => {
    if (CatName) {
      TokenListCall();
    }
  }, [CatName]);

  useEffect(() => {
    CategoryListCall();
  }, []);

  useEffect(() => {
    if (Param_category) {
      catChange(Param_category);
    }
  }, [Param_category]);

  async function catChange(name, type) {
    if (name !== CatName || name === "All") {
      setCatName(name);
      Setnewcollstate(type);
    }
  }

  async function TokenListCall(data = {}) {
    setloading(true);

    var currAddr = Wallet_Details.UserAccountAddr;
    var name = CatName;
    if (data.CatName) {
      name = data.CatName;
    }

    var from = typefunfix11;

    var payload = {
      limit: CatName === "All" ? 100 : 100,
      page:
        CatBasedTokenList[name] && CatBasedTokenList[name].page
          ? CatBasedTokenList[name].page
          : 1,
      currAddr: currAddr,
      CatName: name,
      from: "filter",
    };

    Set_LoadDisable(true);

    CatBasedTokenList.loader = true;
    setCatBasedTokenList(CatBasedTokenList);

    var resp = await CollectiblesList_Home(payload);

    CatBasedTokenList.loader = false;
    setCatBasedTokenList(CatBasedTokenList);
    Set_LoadDisable(false);

    if (
      resp &&
      resp.data &&
      resp.data.from === "token-collectibles-list-home" &&
      resp.data.list &&
      resp.data.list.length > 0
    ) {
      setTokenList(TokenList.concat(resp.data.list));
      setCreatorcount(resp.data.list.length);
      if (typeof CatBasedTokenList[name] == "undefined") {
        CatBasedTokenList[name] = { page: 1, list: [] };
      }
      CatBasedTokenList[name].list = CatBasedTokenList[name].list.concat(
        resp.data.list
      );
      setCatBasedTokenList([]);

      setCatBasedTokenList(CatBasedTokenList);
    } else {
      setload(false);
      if (CatBasedTokenList[name]?.onmore) {
        CatBasedTokenList[name].onmore = false;
      }
      setCatBasedTokenList([]);
      setCatBasedTokenList(CatBasedTokenList);
    }
    setloading(false);
    setloadImage(false);
  }

  async function CategoryListCall() {
    axios
      .get(`${config.vUrl}/token/category/list`)
      .then((response) => {
        if (response && response.data && response.data.list) {
          setCategorylist(response.data.list);
        }
      })
      .catch((e) => {});
  }

  const onLoadMore = () => {
    setloadImage(true);
    CatBasedTokenList[CatName].page = CatBasedTokenList[CatName].page + 1;
    setCatBasedTokenList(CatBasedTokenList);

    TokenListCall({
      page: CatBasedTokenList[CatName].page + 1,
    });
  };

  return loading ? (
    <Loader />
  ) : (
    <>
      <div className="explorepage otherpage">
        <Placeabid
          ref={PlaceABidForwardRef}
          Set_MyItemAccountAddr={Set_MyItemAccountAddr}
          Set_tokenCounts={Set_tokenCounts}
          Set_item={Set_item}
          Set_tokenCounts_Detail={Set_tokenCounts_Detail}
          Set_MyTokenBalance={Set_MyTokenBalance}
          Set_Bids={Set_Bids}
          Set_AccepBidSelect={Set_AccepBidSelect}
          Set_tokenBidAmt={Set_tokenBidAmt}
          Set_NoOfToken={Set_NoOfToken}
          Set_ValidateError={Set_ValidateError}
          Set_TokenBalance={Set_TokenBalance}
          Set_YouWillPay={Set_YouWillPay}
          Set_YouWillPayFee={Set_YouWillPayFee}
          Set_YouWillGet={Set_YouWillGet}
          Set_BidApply_ApproveCallStatus={Set_BidApply_ApproveCallStatus}
          Set_BidApply_SignCallStatus={Set_BidApply_SignCallStatus}
          MyItemAccountAddr={MyItemAccountAddr}
          tokenCounts={tokenCounts}
          item={item}
          tokenCounts_Detail={tokenCounts_Detail}
          MyTokenBalance={MyTokenBalance}
          Bids={Bids}
          AccepBidSelect={AccepBidSelect}
          tokenBidAmt={tokenBidAmt}
          NoOfToken={NoOfToken}
          ValidateError={ValidateError}
          TokenBalance={TokenBalance}
          YouWillPay={YouWillPay}
          YouWillPayFee={YouWillPayFee}
          YouWillGet={YouWillGet}
          BidApply_ApproveCallStatus={BidApply_ApproveCallStatus}
          BidApply_SignCallStatus={BidApply_SignCallStatus}
          AllowedQuantity={AllowedQuantity}
          Service_Fee={Service_Fee}
          set_Service_Fee={set_Service_Fee}
          MyItemAccountAddr_Details={MyItemAccountAddr_Details}
          setConvertVal={setConvertVal}
          convertVal={convertVal}
        />

        <LikeRef ref={LikeForwardRef} setLikedTokenList={setLikedTokenList} />

        <Header />

        <div className="explorepagesec otherpage">
          <div className="container-fluid custom-container">
            <div className="tabsicons mt-5">
              <div className="explorefles">
                <div className="tabbable-panel">
                  <div className="tabbable-line">
                    <ul className="nav nav-tabs ">
                      <li className={CatName === "All" ? "active" : ""}>
                        <a
                          href="#allcategories"
                          data-toggle="tab"
                          onClick={() => catChange("All")}
                        >
                          All Categories
                        </a>
                      </li>

                      {Categorylist.length > 0 &&
                        Categorylist.map((itempro) => {
                          return (
                            <li
                              className={
                                CatName === itempro.name ? "active" : ""
                              }
                            >
                              <a
                                href="#buynows"
                                data-toggle="tab"
                                onClick={() => catChange(itempro.name)}
                              >
                                {itempro.name}
                              </a>
                            </li>
                          );
                        })}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="tab-content mt-5">
              <div class="tab-pane active" id="allcategories">
                <div className="container">
                  <div className="row mt-5">
                    {CatBasedTokenList &&
                    CatName &&
                    CatBasedTokenList[CatName] &&
                    CatBasedTokenList[CatName].list &&
                    CatBasedTokenList[CatName].list.length > 0 ? (
                      CatBasedTokenList[CatName].list.map((item) => {
                        return isEmpty(item.tokenowners_current) !== true ? (
                          <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-xs-6 col-6 card_client">
                            <Tokencard
                              item={item}
                              Set_item={Set_item}
                              LikedTokenList={LikedTokenList}
                              setLikedTokenList={setLikedTokenList}
                              hitLike={
                                LikeForwardRef.current &&
                                LikeForwardRef.current.hitLike
                              }
                              PlaceABid_Click={
                                PlaceABidForwardRef?.current?.PlaceABid_Click
                              }
                              Set_Bids={Set_Bids}
                              Bids={item.myBid}
                              Set_BuyOwnerDetailFirst={Set_BuyOwnerDetailFirst}
                              Set_tokenCounts_Detail={Set_tokenCounts_Detail}
                              Set_MyTokenBalance={Set_MyTokenBalance}
                              Set_MyTokenDetail={Set_MyTokenDetail}
                              Set_AllowedQuantity={Set_AllowedQuantity}
                              Set_YouWillPay={Set_YouWillPay}
                              Set_YouWillPayFee={Set_YouWillPayFee}
                              Set_YouWillGet={Set_YouWillGet}
                              setConvertVal={setConvertVal}
                              convertVal={convertVal}
                            />
                          </div>
                        ) : (
                          ""
                        );
                      })
                    ) : (
                      <div className="text-center py-5 col-12 notfound">
                        <div className="text-center py-3  no_items_row">
                          <p className="not_found_text">No items found</p>

                          <p className="not_found_text_sub">
                            Come back soon! or try to browse something.
                          </p>
                        </div>
                      </div>
                    )}
                    {load && (
                      <div className="loadmorebtn">
                        {loadImage ? (
                          <img src={Load} />
                        ) : (
                          CatName === "All" && (
                            <button
                              disabled={LoadDisable}
                              onClick={() => onLoadMore()}
                              className="connectwallet mt-4"
                            >
                              Load More
                            </button>
                          )
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div class="tab-pane" id="tab_default_4">
                <div className="infotabdetails mb-4">
                  <div className="infotabdetailssec">
                    <div>
                      <img src={Profile} alt="Infoimage" />
                    </div>
                    <div>
                      <h2>Creater</h2>
                      <p>Lorem Ipsum</p>
                    </div>
                  </div>
                  <div className="infotabdetailsroyal">
                    <p>25%</p>
                    <p>Royalty to the Creater</p>
                  </div>
                </div>
                <div className="infotabdetails mb-4">
                  <div className="infotabdetailssec">
                    <div>
                      <img src={Profile} alt="Infoimage" />
                    </div>
                    <div>
                      <h2>Creater</h2>
                      <p>Lorem Ipsum</p>
                    </div>
                  </div>
                  <div className="infotabdetailsroyal">
                    <p>25%</p>
                    <p>Royalty to the Creater</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer routes={dashboardRoutes} />
      </div>
    </>
  );
}
