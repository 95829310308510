import React, { useState, useRef, useEffect } from "react";
import Header from "../components/Header/Head.js";
import Footer from "../components/Footer/Footer.js";
import $ from "jquery";
import axios from "axios";
import {
  getprofile,
  ParamAccountAddr_Detail_Get,
  coverImage,
  updatePayment,
} from "../actions/v1/user";
import Banner from "../assets/images/keyboard_fin.webp";
import { Link, useParams } from "react-router-dom";
import Profile from "../assets/images/png_unblk/AppIcon.png";
import Tokencard from "../components/seperate/tokencard.js";
import {
  CollectiblesList_MyItems,
  getAllCounts,
  kyctokencall,
  datacheck,
  getEvent,
  KYBcall,
  KYBCheck,
} from "../actions/v1/token";
import { Share } from "../components/share/share.js";
import isEmpty from "../lib/isEmpty";
import config from "../lib/config";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Placeabid } from "../components/placeabid/placeabid";
import Loader from "./Loader.js";
import { countryCode } from "./countryCode.js";
import Select from "react-select";
import { ethers } from "ethers";
toast.configure();
let toasterOption = config.toasterOption;
var complycube = {};
const initialFormValue = {
  UserAccountAddr: "",
  FirstName: "",
  LastName: "",
  Email: "",
  name: "",
  ClientWebsite: "",
  RegisterNumber: "",
  InCropCountryCode: "",
  CompanyName: "",
  selCompanyDetails: {},
};

export default function Myitems() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  var PlaceABidForwardRef = useRef();
  var { paramUsername, paramAddress } = useParams();
  const [
    MyItemAccountAddr_Details,
    Set_MyItemAccountAddr_Details,
  ] = React.useState({});
  const [ParamAccountCustomUrl, Set_ParamAccountCustomUrl] = React.useState(
    paramUsername
  );
  const [MyItemAccountAddr, Set_MyItemAccountAddr] = React.useState("");
  const [UserNotFound, Set_UserNotFound] = React.useState(false);
  const [AlldataCount, setAllDataCount] = useState({});
  const [profile, setProfile] = useState({});
  const [coverimage, setcoverphoto] = React.useState("");
  const [profileimage, setprofilephoto] = React.useState(Profile);
  const [ParamAccountAddr, Set_ParamAccountAddr] = React.useState(paramAddress);
  const [LoadDisable, Set_LoadDisable] = useState(false);
  const [Collectibles_Count, Set_Collectibles_Count] = useState(0);
  const [chooseimage, setchooseimage] = React.useState(false);
  const [validateError, setvalidateError] = React.useState({});
  const [LoadmoreStatus, Set_Loadmore] = useState(false);
  const [notFount, Set_NotFount] = useState(false);
  const [Collectibles_List, Set_Collectibles_List] = useState([]);
  const [OnSale_Count, Set_OnSale_Count] = useState(0);
  const [OnSale_List, Set_OnSale_List] = useState([]);
  const [Created_List_Page, Set_Created_List_Page] = useState(1);
  const [OnSale_Page, Set_OnSale_Page] = useState(1);
  const [Owned_Count, Set_Owned_Count] = useState(0);
  const [Owned_List, Set_Owned_List] = useState([]);
  const [Collectibles_List_Page, Set_Collectibles_List_Page] = useState(1);
  const [item, Set_item] = useState({});
  const [LikedTokenList, setLikedTokenList] = React.useState([]);
  const [Bids, Set_Bids] = useState([]);
  const [BuyOwnerDetailFirst, Set_BuyOwnerDetailFirst] = useState({});
  const [tokenCounts_Detail, Set_tokenCounts_Detail] = useState({});
  const [MyTokenBalance, Set_MyTokenBalance] = useState(0);
  const [MyTokenDetail, Set_MyTokenDetail] = useState({});
  const [AllowedQuantity, Set_AllowedQuantity] = useState({});
  const [YouWillPay, Set_YouWillPay] = useState(0);
  const [YouWillPayFee, Set_YouWillPayFee] = useState(0);
  const Wallet_Details = useSelector((state) => state.wallet_connect_context);
  const [YouWillGet, Set_YouWillGet] = useState(0);
  const [convertVal, setConvertVal] = React.useState(0);
  const [kyctoken, Set_kyctoken] = useState("");
  const [formValue, setFormValue] = useState(initialFormValue);
  const [clientid, Set_clientid] = useState("");
  const [tokenCounts, Set_tokenCounts] = useState(0);
  const [AccepBidSelect, Set_AccepBidSelect] = useState(0);
  const [tokenBidAmt, Set_tokenBidAmt] = useState(0);
  const [NoOfToken, Set_NoOfToken] = useState(1);
  const [TokenBalance, Set_TokenBalance] = useState(0);
  const [BidApply_ApproveCallStatus, Set_BidApply_ApproveCallStatus] = useState(
    "init"
  );
  const [BidApply_SignCallStatus, Set_BidApply_SignCallStatus] = useState(
    "init"
  );
  const [Service_Fee, set_Service_Fee] = useState(0);
  const [loading, setloading] = useState(false);
  const [CompanyOption, setCompanyOption] = useState([]);
  const [KYBStatus, setKYBStatus] = useState(false);
  const [KYBPayment, setKYBPayment] = useState(false);
  const [KYBPaymentAmt, setKYBPaymentAmt] = useState(null);
  const [admindada, setadmindata] = useState(null);
  const [PaymentDet, setPaymentDet] = useState(false);
  const [companyname, setcompanyname] = useState("");
  const [incorpcode, setincorpcode] = useState("");

  useEffect(() => {
    if (
      Wallet_Details.UserAccountAddr !== "" ||
      ParamAccountCustomUrl !== "" ||
      paramAddress !== ""
    ) {
      getInit();
    }
  }, [Wallet_Details.UserAccountAddr, coverimage]);

  const getInit = async () => {
    var currAddr = Wallet_Details.UserAccountAddr;
    var payload = {};

    if (
      (ParamAccountAddr !== undefined || ParamAccountCustomUrl !== undefined) &&
      (ParamAccountAddr !== "" || ParamAccountCustomUrl !== "") &&
      (ParamAccountAddr !== undefined || ParamAccountCustomUrl !== undefined)
    ) {
      if (
        ParamAccountAddr &&
        ParamAccountAddr.toString() === currAddr.toString()
      ) {
        Set_MyItemAccountAddr(ParamAccountAddr);

        payload.addr = ParamAccountAddr;
      } else {
        if (ParamAccountAddr !== "") {
          payload.addr = ParamAccountAddr;
        } else if (ParamAccountCustomUrl !== "") {
          payload.customurl = ParamAccountCustomUrl;
        }
      }
    } else {
      Set_MyItemAccountAddr(currAddr);
      payload.addr = currAddr;
    }
    if (payload) {
      await Get_MyItemAccountAddr_Details(payload);

      window.$("#AfterWalletConnected_two").click();
    }
  };

  const Get_MyItemAccountAddr_Details = async (payload) => {
    setloading(true);

    if (
      Wallet_Details.UserAccountAddr !== "" ||
      paramAddress !== "" ||
      ParamAccountCustomUrl !== ""
    ) {
      var Resp = await ParamAccountAddr_Detail_Get(payload);
      setloading(false);

      if (Resp?.data?.User?.curraddress != "") {
        Tab_Data_Call("List", "onsale", true, 1, Resp?.data?.User?.curraddress);
        Set_MyItemAccountAddr(Resp?.data?.User?.curraddress);
        getAllCount(Resp?.data?.User?.curraddress);

        if (Resp?.data?.User) {
          Set_MyItemAccountAddr_Details(Resp.data.User);
          if (Resp?.data?.User?.KYBKYCpayment) setPaymentDet(true);
          if (
            Resp.data.User.KYCVRIFIED != "" &&
            Resp.data.User.KYCVRIFIED !== "clear"
          ) {
            toast.warning("your KYC check not clear please try again");
          }
          getProfileData(Resp?.data?.User?.curraddress);
        }
      } else {
        toast.warning("User not found", toasterOption);
        Set_UserNotFound(true);
      }
    }
  };

  const handleFile = async (e) => {
    if (Wallet_Details.UserAccountAddr == "") {
      toast.warning(" connect your wallet", toasterOption);
    }
    var validExtensions = ["png", "gif", "PNG", "jpg", "JPEG", "jpeg", "JPG"]; //array of valid extensions
    if (e.target && e.target.files) {
      var reader = new FileReader();
      var file = e.target.files[0];

      var fileName = file.name;
      var fileNameExt = fileName.substr(fileName.lastIndexOf(".") + 1);
      if ($.inArray(fileNameExt, validExtensions) === -1) {
        toast.warning(
          "Only these file types are accepted : png,gif,jpg,jpeg",
          toasterOption
        );
        return false;
      }

      const fileSize = file.size;
      if (10000000 < fileSize) {
        toast.warning("Too large. Please upload within 5MB", toasterOption);
        return false;
      }
      if (file == null) {
        toast.warning("Image is Required", toasterOption);
        return false;
      } else {
        reader.onloadend = function (e) {
          setcoverphoto(reader.result);
        }.bind(this);

        let addr = MyItemAccountAddr;
        var reqdata = {
          file,
          currAddr: addr,
          from: "cover",
        };

        setchooseimage(true);

        setvalidateError("");
        var coverimg = await coverImage(reqdata);

        if (coverimg && coverimg.userValue !== undefined) {
          window.$("#editprofile").modal("hide");

          getProfileData(Wallet_Details.UserAccountAddr);
          setchooseimage(false);
        } else {
          setcoverphoto(Banner);
        }
      }
    }
  };

  const getProfileData = async (addrchk) => {
    const currAddr = isEmpty(paramAddress) ? addrchk : paramAddress;
    let reqData = { currAddr };
    var data = await getprofile(reqData);

    if (data.admindata) {
      var AmtInUsd =
        Number(data?.admindata[0]?.Kyb) + Number(data?.admindata[0]?.Kyc);
      const USD = "USD";
      const currencySymbol = config.kybcurreny;
      var dataaas = await axios.get(
        `https://min-api.cryptocompare.com/data/price?fsym=${USD}&tsyms=${currencySymbol}`
      );
      var AmtInCurreny =
        dataaas?.data?.AVAX && AmtInUsd ? dataaas?.data?.AVAX * AmtInUsd : 0;

      setKYBPaymentAmt(AmtInCurreny);
    }
    setadmindata(data.admindata);
    setProfile(data.userValue);

    if (data.userValue) {
      if (data.userValue.coverimage !== "") {
        let coverimage = `${config.Back_URL}/images/coverimages/${data.userValue._id}/${data.userValue.coverimage}`;
        setcoverphoto(coverimage);

        if (data?.userValue?.image != "") {
          let profileimage = `${config.Back_URL}/images/${data.userValue._id}/${data.userValue.image}`;

          setprofilephoto(profileimage);
        }
      }
      setloading(false);
    }
  };
  const getAllCount = async (addr) => {
    setloading(true);

    var payload = {};
    payload.currAddr = addr;
    var countdata = await getAllCounts(payload);

    if (countdata && countdata.data && countdata.data.sucess) {
      setAllDataCount(countdata.data);
      setloading(false);
    }
  };

  const handleFile3 = async (event) => {
    if (Wallet_Details.UserAccountAddr == "") {
      toast.warning(" connect your wallet", toasterOption);
    }
    var validExtensions = ["png", "gif", "PNG", "jpg", "JPEG", "jpeg", "JPG"];

    event.preventDefault();
    var reader = new FileReader();
    if (event.target && event.target.files) {
      if (event.target.files && event.target.files[0]) {
        const { id, files } = event.target;

        var file = event.target.files[0];
        var url = reader.readAsDataURL(file);
        var fileName = file.name;
        var fileNameExt = fileName.substr(fileName.lastIndexOf(".") + 1);
        if ($.inArray(fileNameExt, validExtensions) === -1) {
          toast.error(
            "Only these file types are accepted : png,gif,jpg,jpeg",
            toasterOption
          );
          return false;
        }
        const fileSize = file.size;
        if (30000000 < fileSize) {
          toast.error("File size exceeds 30 MB", toasterOption);
          return false;
        }
        if (file == null) {
          toast.error("Image is Required", toasterOption);
          return false;
        } else {
          reader.onloadend = function (e) {
            if (reader.result) {
              setprofilephoto(reader.result);
              // setImageVal1(file.name);
            }
          };

          let addr = MyItemAccountAddr;
          var reqdata = {
            file,
            currAddr: addr,
            from: "profile",
          };
          var coverimg = await coverImage(reqdata);
        }
      } else {
        setprofilephoto(profile);
      }
    }
  };

  async function Tab_Data_Call(Target, TabName, init, pageno, data) {
    if (MyItemAccountAddr !== undefined || data !== undefined) {
      var pagelimit = 10;
      var ReqData = {
        Addr: MyItemAccountAddr === "" ? data : MyItemAccountAddr,
        MyItemAccountAddr: MyItemAccountAddr === "" ? data : MyItemAccountAddr,
        ParamAccountAddr: ParamAccountAddr,
        UserAccountAddr: Wallet_Details.UserAccountAddr,
        Target: Target,
        TabName: TabName,
        init: init,
        limit: pagelimit,
        from: "My-Items",
        page: pageno,
      };

      Set_LoadDisable(true);
      var Resp = {};
      Resp = await CollectiblesList_MyItems(ReqData);

      var RespNew = await CorrectDataGet(Resp);

      if (
        (Target === "Count" && typeof RespNew.count != "undefined") ||
        (Target === "List" && RespNew.list)
      ) {
        Set_LoadDisable(false);
        if (TabName === "collectibles") {
          if (Target === "Count") {
            Set_Collectibles_Count(RespNew.count);
          }
          if (Target === "List") {
            if (pagelimit == RespNew.list.length) {
              Set_Loadmore(true);
            }
            if (pageno == 1) {
              if (RespNew.list.length == 0) {
                Set_NotFount(true);
              }
              Set_Collectibles_List(RespNew.list);
            } else {
              Set_Collectibles_List(Collectibles_List.concat(RespNew.list));
            }
          }
        } else if (TabName === "onsale") {
          if (Target === "Count") {
            Set_OnSale_Count(RespNew.count);
          }
          if (Target === "List") {
            if (pagelimit == RespNew.list.length) {
              Set_Loadmore(true);
            }
            if (pageno == 1) {
              if (RespNew.list.length == 0) {
                Set_NotFount(true);
              }
              Set_OnSale_List(RespNew.list);
            } else {
              Set_OnSale_List(OnSale_List.concat(RespNew.list));
            }
          }
        } else if (TabName === "owned") {
          if (Target === "Count") {
            Set_Owned_Count(RespNew.count);
          }
          if (Target === "List") {
            Set_Owned_List(RespNew.list);
          }
        }
      }
    }
    return true;
  }

  async function CorrectDataGet(Resp, Target) {
    var RetData = { count: 0, list: [] };
    if (
      Resp &&
      Resp.data &&
      Resp.data.Target &&
      Resp.data.list &&
      Resp.data.list.length > 0 &&
      Resp.data.list[0]
    ) {
      if (Resp.data.Target === "Count" && Resp.data.list[0].count) {
        RetData.count = Resp.data.list[0].count;
      } else if (Resp.data.Target === "List" && Resp.data.list[0]) {
        RetData.list = Resp.data.list;
      }
    }

    return RetData;
  }

  async function Tab_Click(TabName, pageno) {
    Set_NotFount(false);
    if (pageno > 0) {
      await Tab_Data_Call("List", TabName, true, pageno);
    } else {
      Set_Loadmore(false);
      await Tab_Data_Call("List", TabName, true, 1);
    }
    if (TabName === "collectibles") {
      Set_Collectibles_List_Page(Collectibles_List_Page + 1);
    } else if (TabName === "onsale") {
      Set_OnSale_Page(OnSale_Page + 1);
    } else if (TabName === "created") {
      Set_Created_List_Page(Created_List_Page + 1);
    }
  }

  const AfterWalletConnected_two = async (event) => {
    event.preventDefault();

    await Tab_Data_Call("List", "onsale", "true", OnSale_Page);
  };

  const startVerification = async (kyctoken1, ClientID) => {
    try {
      if (kyctoken1) {
        complycube = await window.ComplyCube.mount({
          token: String(kyctoken1),
          containerId: "complycube-mount",
          stages: [
            "intro",
            "documentCapture",
            {
              name: "faceCapture",
              options: {
                mode: "video",
              },
            },
            {
              name: "completion",
              options: {
                heading: "Thank you for completing the process",
                message: ["we will get in touch shortly"],
              },
            },
          ],

          onComplete: async function (data) {
            console.info("Capture complete", data);
            if (data) {
              var payload = {
                curraddr: Wallet_Details.UserAccountAddr,
                documentId: data.documentCapture.documentId,
                documentType: data.documentCapture.documentType,
                liveVideoId: data.faceCapture.liveVideoId,
                clientid: ClientID,
                type: "enhanced_identity_check",
              };

              var checkdata = await datacheck(payload);
            }
          },
          onModalClose: function () {
            // Handle the modal closure attempt
            window.ComplyCube.updateSettings({ isModalOpen: false });
          },
          onError: function ({ type, message }) {
            if (type === "token_expired") {
              // Request a new SDK token
            } else {
              // Handle other errors
            }
          },
        });
      }
    } catch (err) {}
  };

  var ValidateError = async (data) => {
    var valerr = {};
    const pattern = /^[\w.-]+@[\w.-]+\.\w+$/;
    var re = /^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/;

    if (KYBStatus) {
      if (data.FirstName === "") {
        valerr.FirstName = "FirstName is Required";
      }
      if (data.LastName === "") {
        validateError.LastName = "LastName is Required";
      }
    } else {
      if (data.name === "") {
        valerr.name = "Company Name is Required";
      }
      if (
        data.InCropCountryCode === "" ||
        data.InCropCountryCode === undefined
      ) {
        valerr.InCropCountryCode = "please Select Company country";
      }

      if (data.ClientWebsite === "") {
        valerr.ClientWebsite = "ClientWebsite is require";
      }
      if (!re.test(String(data.ClientWebsite))) {
        valerr.ClientWebsite = "Invalid Format";
      }

      if (data.RegisterNumber === "") {
        valerr.RegisterNumber = "RegisterNumber is require";
      }
      if (data.CompanyName === "") {
        valerr.CompanyName = "CompanyName is require";
      }
      if (data.selCompanyDetails === "") {
        valerr.selCompanyDetails = "CompanyName is require";
      }
    }
    if (data.Email === "") {
      valerr.Email = "Email is Required";
    }
    if (data.Email && !pattern.test(data.Email)) {
      valerr.Email = "Email is Invalid";
    }
    return valerr;
  };

  var onchange = async (e) => {
    if (e.target.id === "CompanyName") setcompanyname(e.target.value);
    e.preventDefault();
    let formData = { ...formValue, ...{ [e.target.id]: e.target.value } };
    setFormValue(formData);
  };

  var onSubmit = async () => {
    var errorUI = await ValidateError(formValue);
    if (isEmpty(errorUI)) {
    } else {
      setvalidateError(errorUI);
      toast.warn("please fix the issue");
    }

    var data = {
      currAddr: Wallet_Details.UserAccountAddr,
      email: formValue.Email,
      firstname: formValue.FirstName,
      lastname: formValue.LastName,
    };
    if (data?.currAddr != "") {
      const kyctoken = await kyctokencall(data);

      Set_kyctoken(kyctoken?.data?.token);
      Set_clientid(kyctoken?.data?.clientId);
      if (kyctoken?.data?.token && kyctoken?.data?.clientId) {
        window.$("#kycupdate").modal("hide");
        startVerification(kyctoken.data.token, kyctoken.data.clientId);
      }
    }
  };

  const onKYBsubmit = async () => {
    var errorUI = await ValidateError(formValue);

    if (isEmpty(errorUI)) {
    } else {
      setvalidateError(errorUI);
      toast.warn("please fix the issue");
    }
    var kybverify = await KYBCheck(formValue);
  };

  const OnSelChange = async (e, id) => {
    if (id === "InCropCountryCode") {
      setincorpcode(e.value);
      let formData = {
        ...formValue,
        ...{
          [id]: e.value,
          ["UserAccountAddr"]: Wallet_Details?.UserAccountAddr,
        },
      };

      setFormValue(formData);
    }

    if (id === "selcomp") {
      let formData = {
        ...formValue,
        ...{ ["companyID"]: e?.id, ["selCompanyDetails"]: e },
      };
      setFormValue(formData);
    }
  };

  useEffect(() => {
    if (incorpcode != "" && companyname != "") {
      lookupcompany();
    }
  }, [incorpcode, companyname]);

  const lookupcompany = async () => {
    var data = { companyname, incorpcode };
    const companyopt = await KYBcall(data);

    setCompanyOption(
      companyopt?.data?.returndata?.length > 0 && companyopt?.data?.returndata
    );
  };

  const sendTrans = async () => {
    try {
      if (Wallet_Details.UserAccountAddr !== "") {
        if (Wallet_Details?.UserAccountBal > KYBPaymentAmt) {
          var weiAmount = ethers.utils.parseUnits(String(KYBPaymentAmt), 18);

          var receiver = config.adminaddr;

          const provider = new ethers.providers.Web3Provider(window.ethereum);
          const signer = provider.getSigner();

          const tx = await signer.sendTransaction({
            to: receiver,
            value: weiAmount,
          });

          if (tx.hash) {
            setKYBPayment(true);

            var data = {
              KYBPaymentAmt,
              receiver,
              sender: Wallet_Details.UserAccountAddr,
            };
            updatePayment(data);
            window.$("#PurchaseStep_modal").modal("hide");
            toast.success("payment done successfully");
            setTimeout(() => {
              window.location.reload();
            }, 2000);
          }
        } else {
          toast.warn("Please check the balance");
        }
      } else {
        toast.warn("Please connect your wallet");
      }
    } catch (err) {}
  };

  return loading ? (
    <Loader />
  ) : (
    <>
      <div className="myitemspage otherpage">
        <div
          id="AfterWalletConnected_two"
          onClick={(e) => AfterWalletConnected_two(e)}
        ></div>

        <Placeabid
          ref={PlaceABidForwardRef}
          Set_MyItemAccountAddr={Set_MyItemAccountAddr}
          Set_tokenCounts={Set_tokenCounts}
          Set_item={Set_item}
          Set_tokenCounts_Detail={Set_tokenCounts_Detail}
          Set_MyTokenBalance={Set_MyTokenBalance}
          Set_Bids={Set_Bids}
          Set_AccepBidSelect={Set_AccepBidSelect}
          Set_tokenBidAmt={Set_tokenBidAmt}
          Set_NoOfToken={Set_NoOfToken}
          Set_ValidateError={setvalidateError}
          Set_TokenBalance={Set_TokenBalance}
          Set_YouWillPay={Set_YouWillPay}
          Set_YouWillPayFee={Set_YouWillPayFee}
          Set_YouWillGet={Set_YouWillGet}
          Set_BidApply_ApproveCallStatus={Set_BidApply_ApproveCallStatus}
          Set_BidApply_SignCallStatus={Set_BidApply_SignCallStatus}
          MyItemAccountAddr={MyItemAccountAddr}
          tokenCounts={tokenCounts}
          item={item}
          tokenCounts_Detail={tokenCounts_Detail}
          MyTokenBalance={MyTokenBalance}
          Bids={Bids}
          AccepBidSelect={AccepBidSelect}
          tokenBidAmt={tokenBidAmt}
          NoOfToken={NoOfToken}
          ValidateError={ValidateError}
          TokenBalance={TokenBalance}
          YouWillPay={YouWillPay}
          YouWillPayFee={YouWillPayFee}
          YouWillGet={YouWillGet}
          BidApply_ApproveCallStatus={BidApply_ApproveCallStatus}
          BidApply_SignCallStatus={BidApply_SignCallStatus}
          AllowedQuantity={AllowedQuantity}
          Service_Fee={Service_Fee}
          set_Service_Fee={set_Service_Fee}
          MyItemAccountAddr_Details={MyItemAccountAddr_Details}
          setConvertVal={setConvertVal}
          convertVal={convertVal}
        />
        <Header />
        <div className="myitemspagesec">
          <div className="container-fluid custom-container">
            <div className="myitemsapge">
              <div className="myitemspagecontent mt-5">
                <div className="myitemsbanner">
                  {coverimage && coverimage != "" ? (
                    <img src={coverimage} />
                  ) : (
                    <img src={Banner} />
                  )}
                  {Wallet_Details.UserAccountAddr ===
                    MyItemAccountAddr_Details.curraddress && (
                    <h3
                      className="myitemsedit"
                      data-bs-toggle="modal"
                      data-bs-target="#editprofile"
                    >
                      Edit Cover
                    </h3>
                  )}
                </div>
              </div>
              <div className="myitemsprofile">
                <div className="before_inter">
                  <div className="image_nd_border">
                    {profileimage != "" ? (
                      <img src={profileimage} />
                    ) : (
                      <img src={Profile} />
                    )}
                  </div>
                </div>
                {Wallet_Details.UserAccountAddr ===
                  MyItemAccountAddr_Details.curraddress && (
                  <div className="filetupepend">
                    <i class="fas fa-pencil-alt"></i>
                    <input
                      type="file"
                      id="photo"
                      onChange={(e) => handleFile3(e)}
                    />
                  </div>
                )}
              </div>
              <div className="myitemspage">
                {profile?.name != "" ? <h2>{profile?.name}</h2> : <></>}

                <h4>{profile?.curraddress}</h4>
                {profile?.bio != "" ? (
                  <p className="nftcollect">{profile?.bio}</p>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="myitemsshareicons">
              {Wallet_Details.UserAccountAddr ===
                MyItemAccountAddr_Details.curraddress && (
                <Link to="/editprofile">
                  <i class="fas fa-pencil-alt"></i>
                </Link>
              )}
              <i
                class="fas fa-share-alt"
                data-bs-toggle="modal"
                data-bs-target="#shareicons"
              ></i>
            </div>
            <div className="text-center mt-5">
              {Wallet_Details.UserAccountAddr === paramAddress ||
              paramAddress === undefined ? (
                Wallet_Details.UserAccountAddr != "" && !PaymentDet ? (
                  <button
                    className="connectwallet"
                    data-bs-toggle="modal"
                    data-bs-target="#PurchaseStep_modal"
                    onClick={() =>
                      Wallet_Details.UserAccountAddr != ""
                        ? ""
                        : toast.warn("please connect your wallet")
                    }
                  >
                    Business Verification
                  </button>
                ) : (
                  clientid === "" &&
                  kyctoken === "" &&
                  Wallet_Details.UserAccountAddr !== "" &&
                  (MyItemAccountAddr_Details.KYCVRIFIED === "" ? (
                    <button
                      className="connectwallet"
                      data-bs-toggle="modal"
                      data-bs-target="#kycupdate"
                    >
                      {KYBStatus ? "KYC verify" : "KYB verify"}
                    </button>
                  ) : MyItemAccountAddr_Details.KYCVRIFIED === "clear" ? (
                    <button className="connectwallet">verified</button>
                  ) : MyItemAccountAddr_Details.KYCVRIFIED !== "clear" ||
                    MyItemAccountAddr_Details.KYCVRIFIED !== "" ? (
                    <button
                      className="connectwallet"
                      data-bs-toggle="modal"
                      data-bs-target="#kycupdate"
                    >
                      try again
                    </button>
                  ) : (
                    ""
                  ))
                )
              ) : (
                ""
              )}
            </div>
            <div className="tabbable-panel">
              <div className="tabbable-line">
                <ul className="nav nav-tabs">
                  <li className="active">
                    <Link
                      to="#onsale"
                      data-toggle="tab"
                      onClick={() => Tab_Click("onsale", 0)}
                    >
                      On Sale{" "}
                      <span>
                        ( {AlldataCount && AlldataCount.onsaleCount} )
                      </span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="#owned"
                      data-toggle="tab"
                      onClick={() => Tab_Click("collectibles", 0)}
                    >
                      Owned{" "}
                      <span>
                        ({AlldataCount && AlldataCount.collectibleCount})
                      </span>
                    </Link>
                  </li>
                </ul>
              </div>
              <div class="tab-content mt-5">
                {loading ? (
                  <Loader />
                ) : (
                  <div class="tab-pane active" id="onsale">
                    {OnSale_List.length === 0 ? (
                      <div className="text-center py-5">
                        {notFount === true && (
                          <>
                            <p className="not_found_text">Item Not Found</p>
                          </>
                        )}

                        <div className="mt-3"></div>
                      </div>
                    ) : (
                      <div className="container">
                        <div className="row mt-5 pb-5">
                          {OnSale_List.map((item) => {
                            return isEmpty(item.tokenowners_current) !==
                              true ? (
                              <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-xs-6 col-6 card_client">
                                <Tokencard
                                  item={item}
                                  Set_item={Set_item}
                                  PlaceABid_Click={
                                    PlaceABidForwardRef?.current
                                      ?.PlaceABid_Click
                                  }
                                  Set_Bids={Set_Bids}
                                  Bids={item.myBid}
                                  Set_BuyOwnerDetailFirst={
                                    Set_BuyOwnerDetailFirst
                                  }
                                  Set_tokenCounts_Detail={
                                    Set_tokenCounts_Detail
                                  }
                                  Set_MyTokenBalance={Set_MyTokenBalance}
                                  Set_MyTokenDetail={Set_MyTokenDetail}
                                  Set_AllowedQuantity={Set_AllowedQuantity}
                                  Set_YouWillPay={Set_YouWillPay}
                                  Set_YouWillPayFee={Set_YouWillPayFee}
                                  Set_YouWillGet={Set_YouWillGet}
                                  setConvertVal={setConvertVal}
                                  convertVal={convertVal}
                                />
                              </div>
                            ) : (
                              ""
                            );
                          })}
                          {LoadmoreStatus == true && (
                            <div className="text-center mt-4 w-100 ">
                              <div id="spinButs">
                                <button
                                  id="onmore"
                                  onClick={() => {
                                    Tab_Click("`onsale`", OnSale_Page);
                                  }}
                                  disabled={LoadDisable}
                                  className="connectwallet"
                                >
                                  Load More
                                </button>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                )}
                <div class="tab-pane" id="owned">
                  {Collectibles_List.length === 0 ? (
                    <div className="text-center py-5">
                      {notFount === true && (
                        <>
                          <p className="not_found_text">Item Not Found</p>
                        </>
                      )}
                      <div className="mt-3"></div>
                    </div>
                  ) : (
                    <div className="container">
                      <div className="row mt-5 pb-5">
                        {Collectibles_List.map((item) => {
                          return isEmpty(item.tokenowners_current) !== true ? (
                            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 card_client">
                              <Tokencard
                                item={item}
                                Set_item={Set_item}
                                PlaceABid_Click={
                                  PlaceABidForwardRef.current.PlaceABid_Click
                                }
                                Set_Bids={Set_Bids}
                                Bids={item.myBid}
                                Set_BuyOwnerDetailFirst={
                                  Set_BuyOwnerDetailFirst
                                }
                                Set_tokenCounts_Detail={Set_tokenCounts_Detail}
                                Set_MyTokenBalance={Set_MyTokenBalance}
                                Set_MyTokenDetail={Set_MyTokenDetail}
                                Set_AllowedQuantity={Set_AllowedQuantity}
                                Set_YouWillPay={Set_YouWillPay}
                                Set_YouWillPayFee={Set_YouWillPayFee}
                                Set_YouWillGet={Set_YouWillGet}
                                setConvertVal={setConvertVal}
                                convertVal={convertVal}
                              />
                            </div>
                          ) : (
                            ""
                          );
                        })}
                        {LoadmoreStatus == true && (
                          <div className="text-center mt-4 w-100 ">
                            <div id="spinButs">
                              <button
                                id="onmore"
                                onClick={() => {
                                  Tab_Click(
                                    "collectibles",
                                    Collectibles_List_Page
                                  );
                                }}
                                disabled={LoadDisable}
                                className="connectwallet"
                              >
                                Load More
                              </button>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
                <div class="tab-pane" id="tab_default_4">
                  <div className="infotabdetails mb-4">
                    <div className="infotabdetailssec">
                      <div>
                        <img src={Profile} alt="Infoimage" />
                      </div>
                      <div>
                        <h2>Creater</h2>
                        <p>{profile?.name}</p>
                      </div>
                    </div>
                    <div className="infotabdetailsroyal">
                      <p>25%</p>
                      <p>Royalty to the Creater</p>
                    </div>
                  </div>
                  <div className="infotabdetails mb-4">
                    <div className="infotabdetailssec">
                      <div>
                        <img src={Profile} alt="Infoimage" />
                      </div>
                      <div>
                        <h2>Creater</h2>
                        <p>Lorem Ipsum</p>
                      </div>
                    </div>
                    <div className="infotabdetailsroyal">
                      <p>25%</p>
                      <p>Royalty to the Creater</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>

      <div
        class="modal fade"
        id="editprofile"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="staticBackdropLabel">
                Update Cover
              </h5>
            </div>
            <div class="modal-body">
              <p>
                Upload new cover for your profile page. We recommended to upload
                images in 1140×260 resolution and supported format GIF PNG JPEG
                JPG
              </p>
            </div>
            <div class="modal-footer">
              <div className="chooseimgaebtn">
                <input
                  className="inp_file"
                  type="file"
                  name="coverimage"
                  id="coverphoto"
                  onChange={(e) => handleFile(e)}
                />
                <button type="button" class="buybtn">
                  Choose Image
                </button>
              </div>
              <div className="text-center mb-2">
                {validateError.file && (
                  <span className="text-danger">{validateError.file}</span>
                )}
              </div>
              <button type="button" class="bidbtn" data-bs-dismiss="modal">
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        class="modal fade"
        id="kycupdate"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="staticBackdropLabel">
                {KYBStatus ? "Company Details" : "Details"}
              </h5>
            </div>

            <div class="modal-body">
              <div className="row px-3">
                {KYBStatus && (
                  <>
                    <div className="col-lg-12 col-md-12 col-12 palcebideths">
                      <input
                        type="text"
                        placeholder="First Name"
                        name="FirstName"
                        id="FirstName"
                        onChange={(e) => onchange(e)}
                      />
                    </div>
                    {validateError.FirstName && (
                      <span className="text-danger">
                        {validateError.FirstName ? validateError.FirstName : ""}
                      </span>
                    )}
                  </>
                )}
                {KYBStatus && (
                  <>
                    <div className="col-lg-12 col-md-12 col-12 palcebideths">
                      <input
                        type="text"
                        placeholder="Last Name"
                        name="LastName"
                        id="LastName"
                        onChange={(e) => onchange(e)}
                      />
                    </div>

                    {validateError.LastName && (
                      <span className="text-danger">
                        {validateError.LastName ? validateError.LastName : ""}
                      </span>
                    )}
                  </>
                )}

                {!KYBStatus && (
                  <>
                    <div className="col-lg-12 col-md-12 col-12 palcebideths">
                      <input
                        type="text"
                        placeholder="Company Name"
                        name="CompanyName"
                        id="CompanyName"
                        onChange={(e) => onchange(e)}
                      />
                    </div>
                    {validateError.Email && (
                      <span className="text-danger">
                        {validateError.Email ? validateError.Email : ""}
                      </span>
                    )}
                  </>
                )}

                {!KYBStatus && (
                  <>
                    <Select
                      id="code"
                      name="incorp"
                      onChange={(e) => OnSelChange(e, "InCropCountryCode")}
                      options={countryCode}
                      classNamePrefix="react-select kr_kycreactslct"
                      placeholder={
                        <div className="kyc_placeholder">Select Country</div>
                      }
                    />

                    {validateError.InCropCountryCode && (
                      <span className="text-danger">
                        {validateError.InCropCountryCode
                          ? validateError.InCropCountryCode
                          : ""}
                      </span>
                    )}
                  </>
                )}

                {
                  <>
                    <div className="col-lg-12 col-md-12 col-12 palcebideths">
                      <input
                        type="text"
                        placeholder="Your Email"
                        name="Email"
                        id="Email"
                        onChange={(e) => onchange(e)}
                      />
                    </div>
                    {validateError.Email && (
                      <span className="text-danger">
                        {validateError.Email ? validateError.Email : ""}
                      </span>
                    )}
                  </>
                }
                {!KYBStatus && (
                  <>
                    <div className="col-lg-12 col-md-12 col-12 palcebideths">
                      <input
                        type="text"
                        placeholder="Your Name"
                        name="name"
                        id="name"
                        onChange={(e) => onchange(e)}
                      />
                    </div>
                    {validateError.name && (
                      <span className="text-danger">
                        {validateError.name ? validateError.name : ""}
                      </span>
                    )}
                  </>
                )}

                {!KYBStatus && (
                  <>
                    <div className="col-lg-12 col-md-12 col-12 palcebideths">
                      <input
                        type="text"
                        placeholder="Company registration Number"
                        name="RegisterNumber"
                        id="RegisterNumber"
                        onChange={(e) => onchange(e)}
                      />
                    </div>
                    {validateError.RegisterNumber && (
                      <span className="text-danger">
                        {validateError.RegisterNumber
                          ? validateError.RegisterNumber
                          : ""}
                      </span>
                    )}
                  </>
                )}

                {!KYBStatus && (
                  <>
                    <div className="col-lg-12 col-md-12 col-12 palcebideths">
                      <input
                        type="text"
                        placeholder="Company Website"
                        name="ClientWebsite"
                        id="ClientWebsite"
                        onChange={(e) => onchange(e)}
                      />
                    </div>
                    {validateError.ClientWebsite && (
                      <span className="text-danger">
                        {validateError.ClientWebsite
                          ? validateError.ClientWebsite
                          : ""}
                      </span>
                    )}
                  </>
                )}

                {!isEmpty(CompanyOption) && !KYBStatus && (
                  <div>
                    <Select
                      onChange={(e) => OnSelChange(e, "selcomp")}
                      options={CompanyOption.length > 0 && CompanyOption}
                      classNamePrefix="react-select kr_kycreactslct"
                      id="name"
                      placeholder={
                        <div className="kyc_placeholder">
                          Select your Company{" "}
                        </div>
                      }
                    />

                    {validateError.selCompanyDetails && (
                      <span className="text-danger">
                        {validateError.selCompanyDetails
                          ? validateError.selCompanyDetails
                          : ""}
                      </span>
                    )}
                  </div>
                )}
              </div>
            </div>
            <div class="modal-footer">
              <div className="chooseimgaebtn">
                {KYBStatus ? (
                  <button type="button" class="buybtn" onClick={onSubmit}>
                    submit
                  </button>
                ) : (
                  <button type="button" class="buybtn" onClick={onKYBsubmit}>
                    submit
                  </button>
                )}
              </div>
              <div className="text-center mb-2">
                {validateError.file && (
                  <span className="text-danger">{validateError.file}</span>
                )}
              </div>
              <button type="button" class="bidbtn" data-bs-dismiss="modal">
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="PurchaseStep_modal"
        data-bs-backdrop="static"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="create_item_modalCenteredLabel"
        aria-hidden="true"
        data-keyboard="false"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-sm"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header text-center">
              <h5 className="modal-title" id="PurchaseStepLabel">
                Follow Steps
              </h5>
            </div>
            <div className="modal-body">
              <form>
                {KYBPaymentAmt && (
                  <div className="text-center my-3">
                    <p className="mt-3 purchase_desc text-center">
                      Send transaction with your wallet to proceed with KYB/KYC
                      process
                    </p>
                    <p className="mt-3 purchase_desc text-center">
                      {KYBPaymentAmt?.toFixed(4)} {config.currencySymbol}
                    </p>

                    <button
                      type="button"
                      onClick={() => sendTrans()}
                      className="buybtn"
                    >
                      Proceed
                    </button>
                  </div>
                )}
              </form>
            </div>
            <div className="modal-footer">
              <Link className="bidbtn" data-bs-dismiss="modal">
                Cancel
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
