import React, { useEffect, useState } from "react";
import Head from "../components/Header/Head";
import { getSubDetail } from "../actions/v1/token";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import config from "../lib/config";
import { getReceipt } from "../actions/v1/getReceiptFunc";
import Web3 from "web3";
import { toast } from "react-toastify";
import DETH_ABI from "../ABI/DETH_ABI.json";
import trade from "../ABI/Trade.json";
import { subdetail, getTodaySubAmt } from "../actions/v1/token";

function Resubscription() {
  var { owner, tokenid, subid } = useParams();
  const history = useHistory();
  const Wallet_Details = useSelector((state) => state.wallet_connect_context);
  const [subscribedBy, Set_subscribedBy] = useState([]);
  const [currMailsubscriber, Set_currMailsubscriber] = useState({});
  const [AllDetails, Set_AllDetails] = useState({});
  const [CoinName, set_CoinName] = React.useState(0);
  const [NewTokenDecimal, setNewTokenDecimal] = React.useState(18);
  const [PurchaseCallStatus, setPurchaseCallStatus] = React.useState("init");
  const [ApproveCallStatus, setApproveCallStatus] = React.useState("init");
  const [NewTokenAddress, setNewTokenAddresss] = React.useState(
    config.tokenAddress
  );
  const [NoOfToken, Set_NoOfToken] = React.useState(1);
  const [ResubDetails, Set_ResubDetails] = React.useState([]);
  const [buyemail, set_buyemail] = React.useState("");
  const [invalidemail, set_invalidemail] = React.useState(false);
  const [approvesend, set_approvesend] = React.useState(0);
  const [subamountInWETH, setsubamountInWETH] = React.useState(0);
  const [allsubdetails, setallsubdetails] = React.useState(null);

  useEffect(() => {
    initget();
  }, [owner, tokenid, subid]);

  const initget = async () => {
    var getamountInWETH = await getTodaySubAmt();
    setsubamountInWETH(Number(getamountInWETH));

    if (owner != null && tokenid != null) {
      const getSubdetails = await getSubDetail({ owner, tokenid, subid });
      var SubscribeDetails =
        getSubdetails?.data?.SubscribedBy.length > 0
          ? getSubdetails?.data?.SubscribedBy.filter(
              (ite) => ite.deleted === false
            )
          : [];
      setallsubdetails(SubscribeDetails);

      var CurrMailSubscriber =
        getSubdetails?.data?.SubscribedBy.length > 0 && subid != "subscribe"
          ? getSubdetails?.data?.SubscribedBy.filter((idd) => idd._id === subid)
          : getSubdetails?.data?.SubscribedBy[0];
      var ResubDetails = SubscribeDetails.filter(
        (ite) => Number(ite.RemainingDays) < 3
      );

      set_buyemail(CurrMailSubscriber?.usermail);
      Set_NoOfToken(ResubDetails.length);
      Set_ResubDetails(ResubDetails);
      Set_AllDetails(SubscribeDetails);
      Set_subscribedBy(SubscribeDetails);
      Set_currMailsubscriber(CurrMailSubscriber);
      set_CoinName(
        CurrMailSubscriber?.length > 0 && CurrMailSubscriber[0]?.CoinName
      );
    }
  };

  async function FormSubmit_StepTwo() {
    setPurchaseCallStatus("start");
    setApproveCallStatus("process");

    if (Wallet_Details.UserAccountAddr != null) {
      if (Wallet_Details.providerss) {
        var web3 = new Web3(Wallet_Details.providerss);
        if (web3) {
          var Approves, handle, receipt, sendVal;
          try {
            if (subamountInWETH) {
              var bidvalue = new web3.eth.Contract(DETH_ABI, NewTokenAddress);
              var subwitqty = NoOfToken * subamountInWETH;
              set_approvesend(subwitqty);

              var getAllowance = await bidvalue.methods
                .allowance(Wallet_Details.UserAccountAddr, config.trade)
                .call();

              if (NewTokenDecimal == 18) {
                var subAndAllowance = web3.utils.toWei(
                  String(
                    Number(web3.utils.fromWei(String(getAllowance))) + subwitqty
                  )
                );

                var sendVal = subAndAllowance;
              }
            }
          } catch (err) {}
          try {
            if (Number(Wallet_Details?.Wen_Bln) > Number(subwitqty)) {
              await bidvalue.methods
                .approve(config.trade, String(sendVal))
                .send({
                  from: Wallet_Details.Accounts,
                })
                .on("transactionHash", async (transactionHash) => {
                  handle = setInterval(async () => {
                    receipt = await getReceipt(web3, transactionHash);
                    clr1();
                  }, 8000);
                });
            } else {
              setApproveCallStatus("try");

              toast.warn("your don't have enough balance");
            }
          } catch (e) {
            setApproveCallStatus("try");
          }
          async function clr1() {
            if (receipt != null) {
              clearInterval(handle);
              if (receipt.status === true) {
                setApproveCallStatus("done");
                setPurchaseCallStatus("init");
              }
            }
          }
        } else {
          window.$("#connect_modal").modal("show");
        }
      }
    } else {
      toast.warn("please connect your wallet");
    }
  }

  async function subscribeproceed() {
    if (Wallet_Details.UserAccountAddr != null) {
      if (Wallet_Details.providerss) {
        var web3 = new Web3(Wallet_Details.providerss);
        if (web3) {
          try {
            var receipt = "";
            var handle = "";
            var CoursetroContract = new web3.eth.Contract(trade, config.trade);
            setPurchaseCallStatus("processing");
            var subAmount = web3.utils.toWei(
              String(subamountInWETH * NoOfToken)
            );

            await CoursetroContract.methods
              .nftSubscription(tokenid, subAmount, config.tokenSymbol)
              .send({ from: Wallet_Details.UserAccountAddr, value: "0" })
              .on("transactionHash", async (transactionHash) => {
                handle = setInterval(async () => {
                  receipt = await getReceipt(web3, transactionHash);

                  clr1();
                }, 8000);
              });
          } catch (e) {
            setPurchaseCallStatus("try");
          }
          async function clr1() {
            if (receipt != null) {
              clearInterval(handle);

              if (receipt.status === true) {
                setApproveCallStatus("done");
                setPurchaseCallStatus("init");

                var sortedarr = subscribedBy.sort(
                  (a, b) => a.RemainingDays - b.RemainingDays
                );
                var finalarr = sortedarr.slice(0, NoOfToken);

                var data = {
                  UserAccountAddr: Wallet_Details.UserAccountAddr,
                  tokenCounts: tokenid,
                  tokenName: tokenid[0]?.tokenName,
                  id: finalarr,
                  subfeesAmtt: Wallet_Details?.SubscribeAmt,
                  newBalance: NoOfToken,
                  buyemail: buyemail,
                  from: "resub",
                };

                const Subsave = await subdetail(data);

                if (Subsave?.data?.status)
                  window.$("#PurchaseStep_modal").modal("hide");

                toast.success("subscribed successfully");

                setTimeout(() => {
                  history.push("/");
                }, 100);
              }
            }
          }
        } else {
          window.$("#connect_modal").modal("show");
        }
      }
    } else {
      toast.warn("please connect your wallet");
    }
  }

  const inputChange = (e) => {
    if (e.target.name === "Quantity") {
      if (e.target.value <= allsubdetails.length) {
        Set_NoOfToken(e.target.value);
      } else {
        toast.warn("please check your Quantity");
      }
    } else {
      set_buyemail(e.target.value);
    }
  };

  async function FormSubmit() {
    if (Wallet_Details.UserAccountAddr !== "") {
      if (buyemail !== "") {
        var emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        if (emailRegex.test(buyemail) && NoOfToken > 0) {
          window.$("#buynow").modal("hide");
          window.$("#PurchaseStep_modal").modal("show");
        } else {
          if (NoOfToken <= 0) {
            toast.warn("Quantity is requiredp");
          }
          set_invalidemail("Please enter a valid email address.");
        }
      } else {
        set_invalidemail("Email is required");
      }
    } else {
      toast.warn("please connect your wallet");
    }
  }

  return (
    <>
      <Head />
      <div className="container-fluid custom-container">
        <div className="resubs_details">
          <div className="text-center">
            <p>Do you want to Resubscribe...?</p>

            {
              <button
                className="connectwallet"
                data-bs-toggle="modal"
                data-bs-target="#buynow"
                onClick={() =>
                  Wallet_Details.UserAccountAddr
                    ? toast.warn("please connect your wallet")
                    : ""
                }
              >
                Resubscribe
              </button>
            }
            {AllDetails?.length > 0 && (
              <p className="resubs_linktext mt-5">
                <Link
                  to={{ pathname: `/resubscribtiondetails`, state: AllDetails }}
                >
                  {" "}
                  Resubscription Details{" "}
                </Link>
              </p>
            )}
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="buynow"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="buynowLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="buynowLabel">
                Check Out
              </h5>
            </div>
            <div className="modal-body p-4">
              <div className="mb-3">
                <label htmlFor="qty">
                  Total {subscribedBy.length} Quantity
                  <span></span>
                </label>

                <div className="mb-3 input_grp_style_1">
                  <input
                    type="text"
                    class="form-control"
                    name="Quantity"
                    id="Quantity"
                    value={NoOfToken}
                    onChange={(e) => inputChange(e)}
                    placeholder="Enter the Quantity"
                  />
                  <span>
                    The subscription for {currMailsubscriber?.tokenName} with a
                    quantity of {ResubDetails.length} is expiring soon{" "}
                  </span>
                </div>
              </div>

              <div className="mb-3">
                <div className="mb-3 input_grp_style_1">
                  <p className="email_label_pop">
                    Enter your email below to receive subscription reminders:
                  </p>
                  <input
                    type="text"
                    class="form-control"
                    name="buyemail"
                    id="buyemail"
                    value={buyemail}
                    onChange={inputChange}
                    placeholder="Your email address"
                  />
                  <span className="text-success"></span>
                </div>
              </div>
              <h3></h3>
              <div className="buynow_popup mb-2">
                <div className="buynow_popupname">
                  <h6>Your Balance</h6>
                </div>
                <div className="buynow_popupname1">
                  <h6>{Number(Wallet_Details?.UserAccountBal).toFixed(4)}</h6>
                  <h6>{config.currencySymbol}</h6>
                </div>
              </div>

              <div className="buynow_popup mb-2">
                <div className="buynow_popupname">
                  <h6>Subscribe fee for {NoOfToken} quantity </h6>
                </div>
                <div className="buynow_popupname1">
                  <h6>
                    {Wallet_Details?.SubscribeAmt * NoOfToken +
                      " " +
                      config.tokenSymbol}
                  </h6>
                </div>
              </div>

              <div className="buynow_popup mb-2">
                <div className="buynow_popupname">
                  <h6>Your Token Balance</h6>
                </div>
                <div className="buynow_popupname1">
                  <h6>
                    {String(Wallet_Details.Wen_Bln)} {config.tokenSymbol}
                  </h6>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button className="buybtn" onClick={() => FormSubmit()}>
                {" "}
                Resubscribe{" "}
              </button>
              <button className="bidbtn" data-bs-dismiss="modal">
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="PurchaseStep_modal"
        data-bs-backdrop="static"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="create_item_modalCenteredLabel"
        aria-hidden="true"
        data-keyboard="false"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-sm"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header text-center">
              <h5 className="modal-title" id="PurchaseStepLabel">
                Follow Steps
              </h5>
            </div>

            <div className="modal-body">
              <form>
                {
                  <div className="text-center">
                    <p className="mt-3 purchase_desc text-center">
                      Approve the transaction
                    </p>
                    <button
                      type="button"
                      onClick={() => FormSubmit_StepTwo()}
                      className="buybtn"
                      disabled={
                        ApproveCallStatus === "process" ||
                        ApproveCallStatus === "done"
                      }
                    >
                      {ApproveCallStatus === "process" && (
                        <i
                          className="fa fa-spinner mr-3 spinner_icon"
                          aria-hidden="true"
                          id="circle1"
                        ></i>
                      )}
                      {ApproveCallStatus === "init" && "Approve"}
                      {ApproveCallStatus === "process" && "In-progress..."}
                      {ApproveCallStatus === "done" && "Done"}
                      {ApproveCallStatus === "try" && "Try Again"}
                    </button>
                  </div>
                }
                <div className="text-center my-3">
                  <p className="mt-3 purchase_desc text-center">
                    Send transaction with your wallet
                  </p>
                  <button
                    type="button"
                    onClick={(e) => subscribeproceed(e)}
                    className={"buybtn"}
                    disabled={
                      String(CoinName) !== config.currencySymbol
                        ? ApproveCallStatus === "init" ||
                          ApproveCallStatus === "process"
                        : true &&
                          (PurchaseCallStatus === "processing" ||
                            PurchaseCallStatus === "start" ||
                            PurchaseCallStatus === "done")
                    }
                  >
                    {PurchaseCallStatus === "processing" && (
                      <i
                        className="fa fa-spinner mr-3 spinner_icon"
                        aria-hidden="true"
                        id="circle1"
                      ></i>
                    )}
                    {PurchaseCallStatus === "init" && "Purchase"}
                    {PurchaseCallStatus === "start" && "Purchase"}

                    {PurchaseCallStatus === "processing" && "In-progress..."}
                    {PurchaseCallStatus === "done" && "Done"}
                    {PurchaseCallStatus === "tryagain" && "Try Again"}
                  </button>
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <Link className="bidbtn" data-bs-dismiss="modal">
                Cancel
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Resubscription;
