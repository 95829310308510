import React, { useState, useEffect, useRef } from "react";
import Header from "../components/Header/Head.js";
import Footer from "../components/Footer/Footer.js";
import "animate.css";
import Video from "../components/video/video";
import { useSelector } from "react-redux";
import HomeSkeleton from "./HomeSkeleton.js";
import { CollectiblesList_Home, getpromotiondata } from "../actions/v1/token";
import { Link } from "react-router-dom";
import Tokencard from "../components/seperate/tokencard.js";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import isEmpty from "../lib/isEmpty";
import { Placeabid } from "../components/placeabid/placeabid";
import Loader from "./Loader.js";
import SearchBar from "../views/search_bar.js";
export default function Home() {
  var pauseVideo = document.getElementById("home_modalvideo");
  const videoPause = () => {
    pauseVideo.pause();
    pauseVideo.currentTime = 0;
  };
  const playvideo = () => {
    pauseVideo.play();
  };

  const [Time_Auction_List, Set_Time_Auction_List] = useState([]);
  const [CatName, setCatName] = useState("All");
  const [CatBasedTokenList, setCatBasedTokenList] = useState({ loader: false, All: { page: 1, list: [], onmore: true }, });
  const [TokenList, setTokenList] = useState([]);
  const [Creatorcount, setCreatorcount] = useState(0);
  const [item, Set_item] = useState({});
  const [LikedTokenList, setLikedTokenList] = useState([]);
  const [Bids, Set_Bids] = useState([]);
  const [BuyOwnerDetailFirst, Set_BuyOwnerDetailFirst] = useState({});
  const [tokenCounts_Detail, Set_tokenCounts_Detail] = useState({});
  const [MyTokenBalance, Set_MyTokenBalance] = useState(0);
  const [MyTokenDetail, Set_MyTokenDetail] = useState({});
  const [AllowedQuantity, Set_AllowedQuantity] = useState({});
  const [YouWillPay, Set_YouWillPay] = useState(0);
  const [YouWillPayFee, Set_YouWillPayFee] = useState(0);
  const [YouWillGet, Set_YouWillGet] = useState(0);
  const [convertVal, setConvertVal] = React.useState(0);
  const [liveauc, set_liveauc] = React.useState(false);
  const [MyItemAccountAddr, Set_MyItemAccountAddr] = useState("");
  const [tokenCounts, Set_tokenCounts] = useState(0);
  const [AccepBidSelect, Set_AccepBidSelect] = useState(0);
  const [tokenBidAmt, Set_tokenBidAmt] = useState(0);
  const [NoOfToken, Set_NoOfToken] = useState(1);
  const [ValidateError, Set_ValidateError] = useState({});
  const [TokenBalance, Set_TokenBalance] = useState(0);
  const [BidApply_ApproveCallStatus, Set_BidApply_ApproveCallStatus] = useState( "init" );
  const [BidApply_SignCallStatus, Set_BidApply_SignCallStatus] = useState( "init" );
  const [Service_Fee, set_Service_Fee] = useState(0);
  const [MyItemAccountAddr_Details, Set_MyItemAccountAddr_Details] = useState( {} );
  const [loading, Set_loading] = React.useState(true);
  const [WalletUserDetails, setWalletUserDetails] = React.useState({});
  const [BidArray, setBidArray] = useState([]);
  const Wallet_Details = useSelector((state) => state.wallet_connect_context);

  var PlaceABidForwardRef = useRef();


  useEffect(() => {
    getInit();
  }, [Wallet_Details.UserAccountAddr, liveauc]);

  async function getInit() {
    TokenListCall();
    timeAuctionFUnc();
  }

  const timeAuctionFUnc = async () => {
    var currAddr = Wallet_Details.UserAccountAddr;

    var payload = {
      limit: 6,
      from: "Time",
      currAddr: currAddr,
    };
    var resp = await CollectiblesList_Home(payload);
    setWalletUserDetails(resp?.data?.walletUserDetails);

    if (resp && resp.data && resp.data.list && resp.data.list.length > 0) {
      Set_Time_Auction_List(resp.data.list);

      var bids =
        resp.data.list.length > 0 &&
        resp.data.list
          .map((item, index) => {
            if (!isEmpty(item.tokenowners_current) && !isEmpty(item.higheBd)) {
              return item;
            }
          })
          .filter((ite) => ite && ite);

      setBidArray(bids);
    } else {
      Set_Time_Auction_List([]);
    }
  };

  async function TokenListCall(data = {}) {
    var currAddr = Wallet_Details.UserAccountAddr;

    var name = CatName;
    if (data.CatName) {
      name = data.CatName;
    }

    var payload = {
      limit: 9,
      page:
        CatBasedTokenList[name] && CatBasedTokenList[name].page
          ? CatBasedTokenList[name].page
          : 1,
      currAddr: currAddr,
      CatName: name,
      from: "Home",
    };

    CatBasedTokenList.loader = true;
    var resp = await CollectiblesList_Home(payload);

    setWalletUserDetails(resp?.data?.walletUserDetails);

    Set_loading(false);
    CatBasedTokenList.loader = false;
    if (
      resp &&
      resp.data &&
      resp.data.from === "token-collectibles-list-home" &&
      resp.data.list &&
      resp.data.list.length > 0
    ) {
      setTokenList(TokenList.concat(resp.data.list));
      setCreatorcount(resp.data.list.length);
      if (typeof CatBasedTokenList[name] == "undefined") {
        CatBasedTokenList[name] = { page: 1, list: [] };
      }
      CatBasedTokenList[name].list = resp.data.list;

      setCatBasedTokenList([]);
      setCatBasedTokenList(CatBasedTokenList);
    } else {
      CatBasedTokenList[name].onmore = false;
      setCatBasedTokenList([]);
      setCatBasedTokenList(CatBasedTokenList);
    }
  }

  return (
    <>
      <Placeabid
        ref={PlaceABidForwardRef}
        Set_MyItemAccountAddr={Set_MyItemAccountAddr}
        Set_tokenCounts={Set_tokenCounts}
        Set_item={Set_item}
        Set_tokenCounts_Detail={Set_tokenCounts_Detail}
        Set_MyTokenBalance={Set_MyTokenBalance}
        Set_Bids={Set_Bids}
        Set_AccepBidSelect={Set_AccepBidSelect}
        Set_tokenBidAmt={Set_tokenBidAmt}
        Set_NoOfToken={Set_NoOfToken}
        Set_ValidateError={Set_ValidateError}
        Set_TokenBalance={Set_TokenBalance}
        Set_YouWillPay={Set_YouWillPay}
        Set_YouWillPayFee={Set_YouWillPayFee}
        Set_YouWillGet={Set_YouWillGet}
        Set_BidApply_ApproveCallStatus={Set_BidApply_ApproveCallStatus}
        Set_BidApply_SignCallStatus={Set_BidApply_SignCallStatus}
        MyItemAccountAddr={MyItemAccountAddr}
        tokenCounts={tokenCounts}
        item={item}
        tokenCounts_Detail={tokenCounts_Detail}
        MyTokenBalance={MyTokenBalance}
        Bids={Bids}
        AccepBidSelect={AccepBidSelect}
        tokenBidAmt={tokenBidAmt}
        NoOfToken={NoOfToken}
        ValidateError={ValidateError}
        TokenBalance={TokenBalance}
        YouWillPay={YouWillPay}
        YouWillPayFee={YouWillPayFee}
        YouWillGet={YouWillGet}
        BidApply_ApproveCallStatus={BidApply_ApproveCallStatus}
        BidApply_SignCallStatus={BidApply_SignCallStatus}
        AllowedQuantity={AllowedQuantity}
        Service_Fee={Service_Fee}
        set_Service_Fee={set_Service_Fee}
        MyItemAccountAddr_Details={MyItemAccountAddr_Details}
        setConvertVal={setConvertVal}
        convertVal={convertVal}
      />

      {/* <LikeRef ref={LikeForwardRef} setLikedTokenList={setLikedTokenList} /> */}

      {loading ? (
        <Loader />
      ) : (
        <>
          <Header />
          <Video />

          {loading ? (
            <HomeSkeleton />
          ) : (
            <div className="home_header">
              {CatBasedTokenList[CatName].list.length > 0 && (
                <div className="content-banner">
                  <div className="faq_img faq_img_aligned">
                    <img
                      className="img-fluid"
                      src={require("../assets/images/Artworks/car_artwork.webp")}
                    />
                  </div>

                  <SearchBar />

                  <div className="container-fluid custom-container positionAbs">
                    <div className="homebanerheight hide_homebannerheight">
                      <div className="content">
                        <h1 className="robotoBold">Discover. Grow. Resell.</h1>
                        <div className="learnmore"></div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <section className="mobile_content">
                <div className="homebanerheight">
                  <div className="content">
                    <h1 className="robotoBold">Discover. Grow. Resell.</h1>
                    <div className="learnmore"></div>
                  </div>
                </div>
              </section>

              <section className="subscribe home_subscribe">
                <div className="container-fluid custom-container">
                  <center className="fm_learnmore_windhide">
                    <div
                      className="fm_learnmore_btn"
                      onClick={playvideo}
                      data-bs-toggle="modal"
                      data-bs-target="#video_modal"
                    >
                      <p>Watch the video</p>
                      <img
                        className="fm_playicon"
                        src={
                          require("../assets/images/black/Playvideo.svg")
                            .default
                        }
                      />
                    </div>
                  </center>
                </div>
              </section>
              {loading ? (
                <Loader />
              ) : (
                <section className="recentlyadded">
                  <div className="container">
                    <div className="kodu"></div>
                  </div>
                  <div className="container custom-container">
                    <div className="row slicing_row">
                      <h2 className="fm_topdata_ttl robotoBold">
                        Top Data Tokens
                      </h2>

                      {CatBasedTokenList &&
                      CatName &&
                      CatBasedTokenList[CatName] &&
                      CatBasedTokenList[CatName].list &&
                      CatBasedTokenList[CatName].list.length > 0 ? (
                        CatBasedTokenList[CatName].list.map((item) => {
                          return isEmpty(item.tokenowners_current) !== true ? (
                            <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-xs-6 col-6 card_client">
                              <Tokencard
                                WalletUserDetails={ WalletUserDetails != undefined ? WalletUserDetails : "" }
                                item={item}
                                Set_item={Set_item}
                                PlaceABid_Click={ PlaceABidForwardRef?.current?.PlaceABid_Click }
                                Set_Bids={Set_Bids}
                                Bids={item.myBid}
                                Set_BuyOwnerDetailFirst={ Set_BuyOwnerDetailFirst }
                                Set_tokenCounts_Detail={Set_tokenCounts_Detail}
                                Set_MyTokenBalance={Set_MyTokenBalance}
                                Set_MyTokenDetail={Set_MyTokenDetail}
                                Set_AllowedQuantity={Set_AllowedQuantity}
                                Set_YouWillPay={Set_YouWillPay}
                                Set_YouWillPayFee={Set_YouWillPayFee}
                                Set_YouWillGet={Set_YouWillGet}
                                setConvertVal={setConvertVal}
                                convertVal={convertVal}
                                curraddress={ Wallet_Details.UserAccountAddr != "" ? Wallet_Details.UserAccountAddr : "" }
                              />
                            </div>
                          ) : (
                            ""
                          );
                        })
                      ) : (
                        <div className="text-center py-5 col-12 notfound">
                          <div className="text-center py-3  no_items_row">
                            <p className="not_found_text_sub">
                              We currently don't have any active auctions.
                              Please come back soon or search our marketplace
                              for data NFTs.
                            </p>
                            <div className="mt-3"></div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="text-center mt-2">
                    <Link to="/explore/All">
                      <button className="connectwallet connectwallet_mobbot">
                        {" "}
                        Explore More{" "}
                      </button>
                    </Link>
                  </div>
                </section>
              )}
              {loading ? (
                <Loader />
              ) : (
                <section className="live_auctions" id="Liveaudion">
                  <div className="container">
                    <div className="kodu"></div>
                  </div>
                  <h2 className="robotoBold">Live Auctions</h2>
                  <div className="container custom-container">
                    <div className="row slicing_row">
                      {BidArray.length > 0 ? (
                        BidArray.map((item) => {
                          return (
                            <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-xs-6 col-6 ">
                              <Tokencard
                                item={item}
                                Set_item={Set_item}
                                PlaceABid_Click={ PlaceABidForwardRef.current.PlaceABid_Click }
                                Set_Bids={Set_Bids}
                                Bids={item.myBid}
                                Set_BuyOwnerDetailFirst={ Set_BuyOwnerDetailFirst }
                                Set_tokenCounts_Detail={Set_tokenCounts_Detail}
                                Set_MyTokenBalance={Set_MyTokenBalance}
                                Set_MyTokenDetail={Set_MyTokenDetail}
                                Set_AllowedQuantity={Set_AllowedQuantity}
                                Set_YouWillPay={Set_YouWillPay}
                                Set_YouWillPayFee={Set_YouWillPayFee}
                                Set_YouWillGet={Set_YouWillGet}
                                setConvertVal={setConvertVal}
                                convertVal={convertVal}
                                curraddress={ Wallet_Details.UserAccountAddr != "" ? Wallet_Details.UserAccountAddr : "" }
                              />
                            </div>
                          );
                        })
                      ) : (
                        <div className="text-center pb-5 col-12 notfound">
                          <div className="text-center pb-3 pt-2 no_items_row">
                            <p className="not_found_text_sub newtext_clrliveauc robotoReg footer_xpln">
                              Please come back soon for live auctions or search
                              the marketplace for data NFTs.
                            </p>
                            <div className="mt-3"></div>
                          </div>
                        </div>
                      )}
                    </div>
                    {
                      <div className="text-center mt-2">
                        <Link to="/explore/All">
                          <button className="connectwallet">
                            Explore More{" "}
                          </button>
                        </Link>
                      </div>
                    }
                  </div>
                </section>
              )}
            </div>
          )}

          <div
            class="modal fade "
            id="video_modal"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabindex="-1"
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog  modal-lg  modal-dialog-centered modal-dialog-scrollable">
              {
                <div class="modal-content">
                  <div class="modal-body">
                    <video
                      width="100%"
                      height="100%"
                      id="home_modalvideo"
                      autoplay
                      controls
                    >
                      <source
                        src={require("../assets/images/black/watchdemo.mp4")}
                        type="video/mp4"
                      />
                    </video>

                    <center>
                      <button
                        className="bidbtn modal_cancelbtn"
                        data-bs-dismiss="modal"
                        onClick={videoPause}
                      >
                        Cancel
                      </button>
                    </center>
                  </div>
                </div>
              }
            </div>
          </div>
          <Footer />
        </>
      )}
    </>
  );
}
