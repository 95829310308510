import React, { useEffect, useState, useRef } from "react";
import Header from "../components/Header/Head.js";
import Footer from "../components/Footer/Footer.js";
import Dropdown from "react-bootstrap/Dropdown";
import Profile from "../assets/images/myitemprofile.jpg";
import { Putonsale } from "../components/putonsale/putonsale.js";
import { Placeabid } from "../components/placeabid/placeabid.js";
import { CancelOrder } from "../components/cancelOrder/cancelOrder";
import { Buynow } from "../components/buynow/buynow";
import { Share } from "../components/share/share.js"
import "../assets/css/putonsale.css";
import { useSelector } from "react-redux";
import { Link, useParams, useHistory } from "react-router-dom";
import {
  TokenCounts_Get_Detail_Action,
  setTokenCounts_Get_Detail_Action,
  History,
  likes,
  getsubscribe,
} from "../actions/v1/token";
import config from "../lib/config";
import { LikeRef } from "../components/LikeRef";
import { WalletRef } from "../components/WalletRef";
import { toast } from "react-toastify";
import isEmpty from "../lib/isEmpty";
import { Subscribe } from "../components/Subscribe";
import moment from "moment";
import Loader from "./Loader.js";
toast.configure();
let toasterOption = config.toasterOption;
export default function Info() {
  let history = useHistory();
  var ShareForwardRef = useRef();

  const Wallet_Details = useSelector((state) => state.wallet_connect_context);
  var { owneraddress, collectionaddress, tokenidval, nftname } = useParams();
  const PlaceABidForwardRef = useRef();
  const PutOnSaleForwardRef = useRef();
  const PurchaseNowForwardRef = useRef();
  const CancelOrderForwardRef = useRef();
  const WalletForwardRef = useRef();
  const [his, sethis] = useState([]);
  const [showingLoader, setshowingLoader] = React.useState(false);
  const [AllowedQuantity, Set_AllowedQuantity] = useState(0);
  const [onwer_price, set_onwer_price] = useState({});
  const [BuyOwnerDetailFirst, Set_BuyOwnerDetailFirst] = useState({});
  const [Zero_Price_Detail, Set_Zero_Price_Detail] = useState([]);
  const [OwnersDetailFirst, Set_OwnersDetailFirst] = useState({});
  const [tokenCounts_Detail, Set_tokenCounts_Detail] = useState({});
  const [Bids, Set_Bids] = useState({});
  const [MyTokenBalance, Set_MyTokenBalance] = useState(0);
  const [MyTokenDetail, Set_MyTokenDetail] = useState(0);
  const [token_owner_detail_first, set_token_owner_detail_first] = useState({});
  const [item, Set_item] = useState({});
  const [tokenUsers, setTokenUsers] = useState({});
  const [MyItemAccountAddr, Set_MyItemAccountAddr] = React.useState("");
  const [LikedTokenList, setLikedTokenList] = React.useState([]);
  const [HitItem, Set_HitItem] = useState({});
  const [Service_Fee, set_Service_Fee] = useState(0);
  const [MyItemAccountAddr_Details, Set_MyItemAccountAddr_Details] = useState(
    {}
  );
  const [convertVal, setConvertVal] = React.useState(0);
  const [tokenCounts, Set_tokenCounts] = useState(tokenidval);
  const [tokenBidAmt, Set_tokenBidAmt] = useState(0);
  const [AccepBidSelect, Set_AccepBidSelect] = useState(0);
  const [NoOfToken, Set_NoOfToken] = useState(1);
  const [YouWillPay, Set_YouWillPay] = useState(0);
  const [likeList, setlikeList] = useState(LikedTokenList);
  const [ownercheck, setownercheck] = useState(false);

  const [ValidateError, Set_ValidateError] = useState({});
  const [TokenBalance, Set_TokenBalance] = useState(0);
  const [YouWillPayFee, Set_YouWillPayFee] = useState(0);
  const [YouWillGet, Set_YouWillGet] = useState(0);
  const [ BidApply_ApproveCallStatus, Set_BidApply_ApproveCallStatus, ] = React.useState("init");
  const [BidApply_SignCallStatus, Set_BidApply_SignCallStatus] = React.useState( "init" );
  const [subStatus, Set_subStatus] = React.useState(false);
  const [donegrace, Set_donegrace] = React.useState(false);
  const [tuser, Set_tuser] = React.useState(false);
  const [loading, Set_loading] = React.useState(true);
  const [freenftlimit, set_freenftlimit] = React.useState("");
  const [baseprice, set_baseprice] = React.useState("");
  const [allcurrOwner, Set_allcurrOwner] = React.useState([]);
  const [WalletUserDetails, setWalletUserDetails] = React.useState({});
  const [allSubdetails, setallSubdetails] = React.useState([]);
  const [suboutOfDateQuantity, setsuboutOfDateQuantity] = React.useState([]);
  const [CurrentKeyUser, setCurrentKeyUser] = React.useState(0);

  useEffect(() => {
    (async () => {
      var curAddr = Wallet_Details.UserAccountAddr;
      var payload = {
        curAddr: curAddr,
        tokenCounts: String(tokenidval),
        paramAddress: String(owneraddress).toLowerCase(),
        contractAddress: String(collectionaddress).toLowerCase(),
      };
      await TokenCounts_Get_Detail_Call(payload);
      var dataHistory = await History({ tokenCounts: String(tokenidval) });
      sethis(dataHistory.data);
    })();
  }, [Wallet_Details.UserAccountAddr, donegrace]);

  const TokenCounts_Get_Detail_Call = async (payload) => {
    var curAddr = payload.curAddr;
    setshowingLoader(true);
    var Resp;

    if (payload.refresh === "refresh") {
      toast.success("You refreshed the metadata", toasterOption);
      await setTokenCounts_Get_Detail_Action(payload);
    }
    if (payload.Wallet_Details?.UserAccountAddr != "")
      
    Resp = await TokenCounts_Get_Detail_Action(payload);
    
    setCurrentKeyUser(
      Resp?.data?.TotalKeyword?.length > 0 ? Resp.data.TotalKeyword.length : 0
    );
    setTimeout(() => {
      setshowingLoader(false);
    }, 3000);

    if (
      Resp &&
      Resp &&
      Resp.data &&
      Resp.data.Detail &&
      Resp.data.Detail.Resp
    ) {
      var TokenResp = Resp.data.Detail.Resp;
      if (
        TokenResp &&
        TokenResp.Token &&
        TokenResp.Token[0] &&
        TokenResp.Token[0].tokenowners_current
      ) {
        var sum = 0;
        sum = TokenResp.Token[0].tokenowners_current.reduce(
          (a, b) => a + (b["balance"] || 0),
          0
        );
        Set_AllowedQuantity(sum);
         

        for ( let i = 0; i < TokenResp.Token[0].tokenowners_current.length; i++ ) {
       
          const element = TokenResp.Token[0].tokenowners_current[i];
          set_onwer_price(element);

          if ( element.tokenOwner.toLowerCase() === owneraddress.toLowerCase() ) { Set_BuyOwnerDetailFirst(element); break; }
          if (element.tokenOwner !== curAddr) { Set_Zero_Price_Detail(element); break; }
          if (element.tokenOwner === curAddr) { Set_OwnersDetailFirst(element); break; }
        }
      }
      Set_tokenCounts_Detail(TokenResp);
      
      if (TokenResp.Bids) {
        
        Set_Bids(TokenResp.Bids);
        setWalletUserDetails(TokenResp?.walletUserDetails);
      }

      var IndexVal = -1;
      var tokenOwnInf = {};
      if (TokenResp.Token[0] && TokenResp.Token[0].tokenowners_all && curAddr) {
        var tokenowners_all = TokenResp.Token[0].tokenowners_all;
        var currentowners = tokenowners_all.map(
          (item) => item.balance > 0 && item.tokenOwner
        );
        Set_allcurrOwner(currentowners);
        
        IndexVal = tokenowners_all.findIndex(
          (val) =>
            val.tokenOwner.toString() === curAddr.toString() &&
            val.tokenOwner.toString() === owneraddress.toString() &&
            val.balance > 0
        );
      }
      if (IndexVal > -1) {
        
        Set_MyTokenBalance(tokenowners_all[IndexVal].balance);
        Set_MyTokenDetail(tokenowners_all[IndexVal]);
        var addrs = TokenResp.Token[0].tokenOwnerInfo.curraddress[IndexVal];
        tokenOwnInf.curraddress = addrs;
        tokenOwnInf.name = TokenResp.Token[0].tokenOwnerInfo.name[IndexVal];
        set_token_owner_detail_first(tokenOwnInf);
      } else {
        Set_MyTokenDetail({});
        Set_MyTokenBalance(0);
      }
      
      if (TokenResp.Token && TokenResp.Token[0]) {
        
        Set_item(TokenResp.Token[0]);
  
        TokenResp?.Token[0]?.tokenowners_all?.map((item) => {
          
          if (
            item.tokenOwner.toLowerCase() === config.adminaddr.toLowerCase()
          ) {
            
            set_freenftlimit(item.freeNft);
            set_baseprice(item.basePrice);
          }
        });

        Set_loading(false);
        getSubscribe(TokenResp.Token[0], TokenResp.Tusers);
        Set_tuser(TokenResp.Tusers);
        setTokenUsers(TokenResp.Tusers);
        
      }
    }
  };
  const checkerror = (e, item) => {
    e.target.src = `${config.IPFS_IMG}/${item.ipfsimage}`;
  };

  async function GetUserBal() {
    await WalletForwardRef.current.GetUserBal();
  }

  const showingloader_true = async () => {

  };
  const showingloader_false = async () => {
  };

  const subscribe = async () => {
    window.location.href = `${config.Front_URL}/resubscribtion/${owneraddress}/${tokenidval}/subscribe`;
  };


  const getSubscribe = async (item, tuser) => {
    
    if (Wallet_Details.UserAccountAddr != "") {
      var tokenowners_all = item.tokenowners_current;
      var currOwnerdetails = tokenowners_all.filter(
        (ite) => ite?.tokenOwner?.toLowerCase() === owneraddress.toLowerCase()
      )[0];

      

      if (currOwnerdetails) {
        setownercheck(true);
        
        var data = {
          currAdd: Wallet_Details.UserAccountAddr,
          tokenCount: currOwnerdetails?.tokenCounts,
        };

        var SubscribeStatus = await getsubscribe(data);
        
        var subscribeData =
          SubscribeStatus?.data?.data?.SubscribedBy?.length > 0
            ? SubscribeStatus?.data?.data?.SubscribedBy
            : [];
        setallSubdetails(subscribeData);
        setsuboutOfDateQuantity(
          subscribeData?.length > 0
            ? subscribeData?.filter((ite) => Number(ite.RemainingDays) <= 1)
                .length
            : 0
        );
        var RemainingDaysCheck =
          subscribeData?.length > 0 &&
          subscribeData?.filter((ite) => Number(ite.RemainingDays) > 0);
        if (RemainingDaysCheck.length > 0) Set_subStatus(true);
        var enddate = new Date(SubscribeStatus?.data?.data?.SubscribeEnd);
      }
    }
  };
  const unlockdatas = async () => {
    
    var tokenuser = item.tokenuser.filter(
      (item) => Wallet_Details.UserAccountAddr === item.curraddress
    );
    const state = {
      category: item.tokenName,
      tokencounts: item.tokenCounts,
      ownercheck: ownercheck,
      tokenuser: tokenuser[0],
    };
    const url = { pathname: "/unlock" };
    history.push(url, state);
  };

  return loading ? (
    <Loader />
  ) : (
    <>
      <div className="info otherpage">
        <Header />
        <Putonsale
          ref={PutOnSaleForwardRef}
          Set_HitItem={Set_HitItem}
          item={HitItem}
          Service_Fee={Service_Fee}
          set_Service_Fee={set_Service_Fee}
          
          GetUserBal={GetUserBal}
          againCall={TokenCounts_Get_Detail_Call}
          showingloadertrue={showingloader_true}
          showingloaderfalse={showingloader_false}
          setConvertVal={setConvertVal}
          convertVal={convertVal}
          suboutOfDateQuantity1={suboutOfDateQuantity}
          
        />

     
        <Placeabid
          ref={PlaceABidForwardRef}
          Set_MyItemAccountAddr={Set_MyItemAccountAddr}
          Set_tokenCounts={Set_tokenCounts}
          Set_item={Set_item}
          Set_tokenCounts_Detail={Set_tokenCounts_Detail}
          Set_MyTokenBalance={Set_MyTokenBalance}
          Set_Bids={Set_Bids}
          Set_AccepBidSelect={Set_AccepBidSelect}
          Set_tokenBidAmt={Set_tokenBidAmt}
          Set_NoOfToken={Set_NoOfToken}
          Set_ValidateError={Set_ValidateError}
          Set_TokenBalance={Set_TokenBalance}
          Set_YouWillPay={Set_YouWillPay}
          Set_YouWillPayFee={Set_YouWillPayFee}
          Set_YouWillGet={Set_YouWillGet}
          Set_BidApply_ApproveCallStatus={Set_BidApply_ApproveCallStatus}
          Set_BidApply_SignCallStatus={Set_BidApply_SignCallStatus}
          MyItemAccountAddr={MyItemAccountAddr}
          tokenCounts={tokenCounts}
          item={item}
          tokenOwner={onwer_price}
          tokenCounts_Detail={tokenCounts_Detail}
          MyTokenBalance={MyTokenBalance}
          Bids={Bids}
          AccepBidSelect={AccepBidSelect}
          tokenBidAmt={tokenBidAmt}
          NoOfToken={NoOfToken}
          ValidateError={ValidateError}
          TokenBalance={TokenBalance}
          YouWillPay={YouWillPay}
          YouWillPayFee={YouWillPayFee}
          YouWillGet={YouWillGet}
          BidApply_ApproveCallStatus={BidApply_ApproveCallStatus}
          BidApply_SignCallStatus={BidApply_SignCallStatus}
          AllowedQuantity={AllowedQuantity}
          Service_Fee={Service_Fee}
          set_Service_Fee={set_Service_Fee}
          MyItemAccountAddr_Details={MyItemAccountAddr_Details}
          againCall={TokenCounts_Get_Detail_Call}
          showingloadertrue={showingloader_true}
          showingloaderfalse={showingloader_false}
          setConvertVal={setConvertVal}
          convertVal={convertVal}
        />

        <CancelOrder
          ref={CancelOrderForwardRef}
          Set_HitItem={Set_HitItem}
          item={HitItem}
          GetUserBal={GetUserBal}
          againCall={TokenCounts_Get_Detail_Call}
          showingloadertrue={showingloader_true}
          showingloaderfalse={showingloader_false}
          setConvertVal={setConvertVal}
          convertVal={convertVal}
         
        />

        <Buynow
          ref={PurchaseNowForwardRef}
          Set_HitItem={Set_HitItem}
          item={HitItem}
          GetUserBal={GetUserBal}
          Service_Fee={Service_Fee}
          set_Service_Fee={set_Service_Fee}
          MyItemAccountAddr_Details={MyItemAccountAddr_Details}
          againCall={TokenCounts_Get_Detail_Call}
          showingloadertrue={showingloader_true}
          showingloaderfalse={showingloader_false}
          setConvertVal={setConvertVal}
          convertVal={convertVal}
          
        />
         <Share
            ref={ShareForwardRef}
          />

        <Subscribe item={item} owner={MyTokenDetail.tokenOwner} />
       
        <div className="infosec otherpage">
          <div className="container-fluid custom-container">
            <h1></h1>
            <div className="">
            </div>
            <div className="row pb-5">
              <div className="col-lg-1 col-md-1 col-12"></div>
              <div className="col-lg-4 col-md-4 col-sm-12 col-12">
                <div className="infosecinfo">
                  {item &&
                    item.image &&
                    item.image !== "" &&
                    (["webp", "gif", "jpg", "jpeg", "png"].includes(String(item?.image).split('.').pop()?.toLowerCase()) )&& (
                     
                      <img
                        src={
                          item.additionalImage !== ""
                            ? `${config.Back_URL}/nftImg/${item.tokenCreator}/${item.additionalImage}`
                            : `${config.IPFS_IMG}/${item.ipfsimage}`
                        }
                        onError={(e) => checkerror(e, item)}
                        alt="Collections"
                        className="img-fluid"
                      />
                    )}
                </div>
              </div>
              <div className="col-lg-1 col-md-1 col-12"></div>
              <div className="col-lg-5 col-md-5 col-10 offset-1 offset-md-0">
                <div className="infolist">
                  <h2>{item && item.tokenName}</h2>
                  <div className="infoiconslist">
                    <div>
                      <i
                        class="fas fa-share-alt"
                        data-bs-toggle="modal"
                        data-bs-target="#shareicons"
                      ></i>
                    </div>


                    <div>
                      {collectionaddress !==
                        "0x1419c94d6560b81f16486a4c57c7c66f1253cf20".toLowerCase() &&
                      collectionaddress !==
                        "0x8D43916d784759B46255c0F47e3b67E1c8375e40".toLowerCase() &&
                      collectionaddress !==
                        "0x008505ac19add467B1a6177A27D8D383A078dA26".toLowerCase() &&
                      collectionaddress !==
                        "0x1938f408543A19EF6d6d60C3b8A806eBD4E69236".toLowerCase() ? (
                        <i
                          class="fas fa-sync-alt"
                          onClick={() => {
                            var payload = {
                              curAddr: Wallet_Details.UserAccountAddr,
                              tokenCounts: tokenidval,
                              paramAddress: String(owneraddress).toLowerCase(),
                              contractAddress: String(
                                collectionaddress
                              ).toLowerCase(),
                              refresh: "refresh",
                            };
                            TokenCounts_Get_Detail_Call(payload);
                          }}
                        ></i>
                      ) : (
                        ""
                      )}
                    </div>

                    {MyTokenDetail?.tokenOwner ===
                      Wallet_Details.UserAccountAddr && (
                      <Dropdown className="infodropdown">
                        <Dropdown.Toggle id="dropdown-basic">
                          {String(
                            Wallet_Details.UserAccountAddr
                          ).toLowerCase() !==
                            String(config.adminaddr).toLowerCase() &&
                          ownercheck &&
                          subStatus ? (
                            <div
                              className="subs_bg"
                              onClick={() => unlockdatas()}
                            >
                              <i class="fa-regular fa-keyboard keyboard_icon_info"></i>
                            </div>
                          ) : String(
                              Wallet_Details.UserAccountAddr
                            ).toLowerCase() !==
                              String(config.adminaddr).toLowerCase() &&
                            ownercheck ? (
                            <div
                              className="subs_bg"
                              onClick={() => subscribe()}
                            >
                              <img
                                className="info_subs_icon"
                                src={require("../assets/images/subscribe.png")}
                              />
                            </div>
                          ) : (
                            ""
                          )}
                        </Dropdown.Toggle>

                      </Dropdown>
                    )}
                    {MyTokenDetail?.tokenOwner ===
                      Wallet_Details.UserAccountAddr &&
                      MyTokenDetail.tokenPrice > 0 && (
                        <Dropdown className="infodropdown">
                          <Dropdown.Toggle id="dropdown-basic">
                            <i class="fas fa-ellipsis-h"></i>
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            {MyTokenDetail &&
                              MyTokenDetail.balance > 0 &&
                              MyTokenDetail.tokenPrice > 0 &&
                              String(owneraddress).toLowerCase() ===
                                String(
                                  Wallet_Details.UserAccountAddr
                                ).toLowerCase() && (
                                <Dropdown.Item
                                  href="#/action-4"
                                  data-bs-toggle="modal"
                                  data-bs-target="#cancelOrder"
                                >
                                  Cancel Order
                                </Dropdown.Item>
                              )}

                            {MyTokenDetail?.tokenOwner?.toLowerCase() ===
                              config?.adminaddr?.toLowerCase() &&
                            Number(MyTokenDetail.freeNft) === 0 ? (
                              <Dropdown.Item
                                onClick={() =>
                                  PutOnSaleForwardRef.current.PutOnSale_Click(
                                    item,
                                    token_owner_detail_first,
                                    MyTokenDetail,
                                    suboutOfDateQuantity
                                  )
                                }
                              >
                                Change Price
                              </Dropdown.Item>
                            ) : (
                              MyTokenDetail?.tokenOwner?.toLowerCase() !==
                                config?.adminaddr?.toLowerCase() &&
                              MyTokenDetail &&
                              MyTokenDetail.balance > 0 &&
                              MyTokenDetail.tokenPrice > 0 &&
                              Number(MyTokenDetail.freeNft) === 0 && (
                                <Dropdown.Item
                                  onClick={() =>
                                    PutOnSaleForwardRef.current.PutOnSale_Click(
                                      item,
                                      token_owner_detail_first,
                                      MyTokenDetail,
                                      suboutOfDateQuantity
                                    )
                                  }
                                >
                                  Change Price
                                </Dropdown.Item>
                              )
                            )}
                          </Dropdown.Menu>
                        </Dropdown>
                      )}
                  </div>
                </div>
                <h3 className="instrock_al">
                  {
                  
                    <span>
                      {" "}
                      Total Supply{" "}
                      {owneraddress.toLocaleLowerCase() ===
                      config.adminaddr.toLowerCase()
                        ? onwer_price.balance
                        : onwer_price.balance}
                    </span>
                  }
                </h3>
                <p className="token_descript">{item.tokenDesc}</p>
                <div className="tabbable-line">
                  <ul className="nav nav-tabs ">
                    <li className="active">
                      <a href="#info" data-toggle="tab">
                        Info
                      </a>
                    </li>
                    <li>
                      <a href="#owner" data-toggle="tab">
                        Owners
                      </a>
                    </li>
                    <li>
                      <a href="#bid" data-toggle="tab">
                        Bid
                      </a>
                    </li>
                  </ul>
                  <div class="tab-content mt-5">
                    <div class="tab-pane active" id="info">
                      {item && item.tokenowners_current && (
                        <div className="infotabdetails mb-4">
                          <div className="infotabdetailssec">
                            {item.tokenCreatorInfo.image[0] !== "" ? (
                              <Link
                                to={
                                  item &&
                                  item.tokenCreatorInfo &&
                                  (item.tokenCreatorInfo.customurl[0] !== ""
                                    ? `/${item.tokenCreatorInfo.customurl[0]}`
                                    : `/user/${item.tokenCreator}`)
                                }
                                title={`Creator : ${
                                  item.tokenCreatorInfo.name[0] !== ""
                                    ? item.tokenCreatorInfo.name[0]
                                    : item.tokenCreator
                                }`}
                              >
                                <img
                                  src={`${config.Back_URL}/images/${item.tokenCreatorInfo._id[0]}/${item.tokenCreatorInfo.image[0]}`}
                                  alt="Infoimage"
                                ></img>
                              </Link>
                            ) : (
                              <Link
                                to={`/user/${item.tokenCreator}`}
                                title={`Owner : ${item.tokenCreator}`}
                              >
                                <img src={Profile} alt="Infoimage" />
                              </Link>
                            )}
                            <h2>
                              {item.tokenCreatorInfo.name[0] !== ""
                                ? item.tokenCreatorInfo.name[0]
                                : String(item.tokenCreator)
                                    .slice(0, 8)
                                    .concat("...")}
                            </h2>
                          </div>
                          <h1>Data Token Details</h1>
                          <table class="table">
                            <tbody>
                              <tr>
                                <td className="hidone">Highest Bid</td>

                                <td className="hidone1">
                                  {Bids?.highestBid?.tokenBidAmt > 0
                                    ? Bids.highestBid.tokenBidAmt
                                    : 0.0}
                                  {" " + Bids.highestBid.CoinName
                                    ? Bids.highestBid.CoinName
                                    : "WETH"}
                                </td>
                              </tr>
                              <tr>
                                <td className="hidone"> Token Price</td>
                                {Number(freenftlimit) > 0 &&
                                String(owneraddress).toLowerCase() ===
                                  String(config.adminaddr).toLowerCase() ? (
                                  <td className="hidone1">
                                    0.00 {BuyOwnerDetailFirst.CoinName}
                                  </td>
                                ) : Number(freenftlimit) > 0 &&
                                  String(owneraddress).toLowerCase() !==
                                    String(config.adminaddr).toLowerCase() ? (
                                  <td className="hidone1">
                                    {BuyOwnerDetailFirst?.basePrice !== ""
                                      ? BuyOwnerDetailFirst.basePrice +
                                        " " +
                                        BuyOwnerDetailFirst.CoinName
                                      : 0.0 +
                                        " " +
                                        BuyOwnerDetailFirst.CoinName}{" "}
                                  </td>
                                ) : Number(freenftlimit) <= 0 ? (
                                  <td className="hidone1">
                                    {BuyOwnerDetailFirst?.basePrice !== ""
                                      ? BuyOwnerDetailFirst.basePrice +
                                        " " +
                                        BuyOwnerDetailFirst.CoinName
                                      : 0.0 +
                                        " " +
                                        BuyOwnerDetailFirst.CoinName}{" "}
                                  </td>
                                ) : (
                                  0.0 + " " + BuyOwnerDetailFirst.CoinName
                                )}
                              </tr>
                              <tr>
                                <td className="hidone">Available Tokens</td>

                                <td className="hidone1">
                                  {config.adminaddr.toLowerCase() ===
                                  owneraddress.toLowerCase()
                                    ? freenftlimit
                                    : 0}
                                </td>
                              </tr>
                              <tr>
                                <td className="hidone">Royalties</td>
                                <td className="hidone1">0.00 WETH</td>
                              </tr>
                              <tr>
                                <td className="hidone">Data Subscription</td>
                                <td className="hidone1">
                                  0.00000000001 WETH per daily active user
                                </td>
                              </tr>
                              <tr>
                                <td className="hidone">Service Fee</td>

                                <td className="hidone1">
                                  <span
                                    className={
                                      item &&
                                      item.tokenCounts +
                                        "-likecount mr-2" +
                                        "badge badge_pink mr-2"
                                    }
                                  >
                                    {Wallet_Details.Service_Fee_buyer / 1e18} %
                                    per transaction
                                  </span>
                                </td>

                              </tr>
                            </tbody>
                          </table>
                      
                        </div>
                      )}
                    </div>
                    <div class="tab-pane" id="owner">
                      {tokenUsers &&
                        tokenUsers.length > 0 &&
                        tokenUsers.map((itemCur, i) => {
                          return (
                            <div className="infotabdetails mb-4">
                              <div className="infotabdetailssec">
                                {itemCur && itemCur?.tusers?.image !== "" ? (
                                  <Link
                                    to={
                                      itemCur &&
                                      itemCur?.tusers &&
                                      itemCur?.tusers.customurl !== ""
                                        ? `/${itemCur?.tusers?.customurl}`
                                        : `/user/${itemCur?.tusers?.curraddress}`
                                    }
                                    title={`Owner : ${itemCur?.tusers?.name}`}
                                  >
                                    <img
                                      src={`${config?.Back_URL}/images/${itemCur?.tusers?._id}/${itemCur?.tusers?.image}`}
                                      alt="Infoimage"
                                    />
                                  </Link>
                                ) : (
                                  <Link
                                    to={`/user/${itemCur?.tusers?.curraddress}`}
                                    title={`Owner : ${itemCur?.tusers?.curraddress}`}
                                  >
                                    <img src={Profile} alt="Infoimage" />
                                  </Link>
                                )}
                                <div>
                                  <div className="d-flex">
                                    <h2>Owned by</h2>
                                    {itemCur?.tusers?.name !== "" ? (
                                      <h2>{itemCur?.tusers?.name}</h2>
                                    ) : (
                                      <h2 title={itemCur?.tokenOwner}>
                                        {(itemCur?.tokenOwner)
                                          .slice(0, 8)
                                          .concat("....")}
                                      </h2>
                                    )}
                                  </div>
                                  {
                                    
                                    <h2>
                                      On Sale for
                                      {Number(freenftlimit > 0) &&
                                      itemCur.tokenOwner.toLocaleLowerCase() ===
                                        config.adminaddr.toLowerCase() ? (
                                        <span className="ml-2"> 0 </span>
                                      ) : Number(freenftlimit) === 0 ? (
                                        <span className="ml-2">
                                          {" "}
                                          {Number(itemCur.tokenPrice) === 0
                                            ? itemCur.basePrice +
                                              " " +
                                              itemCur.CoinName
                                            : itemCur.tokenPrice +
                                              " " +
                                              itemCur.CoinName}
                                        </span>
                                      ) : (
                                        <span className="ml-2">
                                          {" "}
                                          {Number(itemCur.tokenPrice) === 0
                                            ? itemCur.basePrice +
                                              " " +
                                              itemCur.CoinName
                                            : itemCur.tokenPrice +
                                              " " +
                                              itemCur.CoinName}
                                        </span>
                                      )}
                                      {Number(freenftlimit) === 0 && (
                                        <span className="ml-2">
                                      
                                        </span>
                                      )}
                                      {itemCur.tokenOwner.toLocaleLowerCase() ===
                                      config.adminaddr.toLowerCase()
                                        ? itemCur.listquantity > 0 && " each "
                                        : itemCur.quantity > 0 && " each "}
                                      {itemCur.tokenOwner.toLocaleLowerCase() ===
                                      config.adminaddr.toLowerCase() ? (
                                        <span className="mr-2">
                                          {" "}
                                          ( {itemCur.balance}/
                                          {itemCur.listquantity} ){" "}
                                        </span>
                                      ) : (
                                        <span className="mr-2">
                                          {" "}
                                          ({itemCur.quantity}/ {itemCur.balance}
                                          ){" "}
                                        </span>
                                      )}
                                    </h2>
                                  }

                                 

{
(itemCur.tokenOwner).toLowerCase() === (config.adminaddr).toLowerCase()&&itemCur.balance > 0&&itemCur.tokenOwner!==Wallet_Details.UserAccountAddr?
<button className="connectwallet wid_connectwallet mr-3 mt-3" onClick={() => PurchaseNowForwardRef.current.PurchaseNow_Click(item, itemCur,WalletUserDetails)} >Buy</button>
:
itemCur.tokenOwner!==Wallet_Details.UserAccountAddr&&Number(itemCur.tokenPrice)>0&&Number(freenftlimit)===0&&
<button className="connectwallet wid_connectwallet mr-3 mt-3" onClick={() => PurchaseNowForwardRef.current.PurchaseNow_Click(item, itemCur,WalletUserDetails)} >Buy</button>

}


                                </div>
                              </div>
                            </div>
                          );
                        })}
                    </div>


                    <div class="tab-pane" id="bid">
                      {Bids && Bids.pending && Bids.pending.length > 0
                        ? Bids.pending.map((curBid) => {
                            return (
                              <div className="infotabdetails mb-4">
                                {curBid.bidUsers && (
                                  <div className="infotabdetailssec">
                                    <Link
                                      href={
                                        curBid.bidUsers !== undefined &&
                                        (curBid.bidUsers.customurl !== ""
                                          ? `/${curBid.bidUsers.customurl}`
                                          : `/user/${curBid.tokenBidAddress}`)
                                      }
                                    >
                                      {curBid.bidUsers.image !== "" && (
                                        <img
                                          src={`${config.Back_URL}/images/${curBid.bidUsers._id}/${curBid.bidUsers.image}`}
                                          alt="User"
                                        />
                                      )}
                                      {curBid.bidUsers.image === "" && (
                                        <img src={Profile}></img>
                                      )}
                                    </Link>
                                    <div className="ml-3">
                                      <h2 className="mt-0 media_num mt-0 word_break_all m-0">
                                      
                                      
                                        {curBid.tokenBidAmt}
                                        <span> {curBid.CoinName}</span>
                                        <span className="ml-2 mr-2">
                                          
                                        </span>
                                        by{" "}
                                        <span
                                          className="mr-2"
                                          title={
                                            curBid &&
                                            curBid.bidUsers !== undefined &&
                                            (curBid.bidUsers.name !== ""
                                              ? curBid.bidUsers.name
                                              : curBid.tokenBidAddress)
                                          }
                                        >
                                          {curBid.bidUsers !== undefined &&
                                            (curBid.bidUsers.name !== "" ? (
                                              curBid.bidUsers.name
                                            ) : (
                                              <span className="word_brak_txt">
                                                {curBid.tokenBidAddress}
                                              </span>
                                            ))}
                                        </span>{" "}
                                        {AllowedQuantity > 0 && (
                                          <span>
                                            for ( {curBid.pending}/
                                            {curBid.NoOfToken} )
                                          </span>
                                        )}
                                      </h2>

                                      <h2 className="m-0">
                                        {moment(curBid.timestamp).format(
                                          "Do MMMM YYYY, h:mm a"
                                        )}
                                      </h2>

                                      

                                      {Wallet_Details.UserAccountAddr &&
                                        Wallet_Details.UserAccountAddr !==
                                          curBid.tokenBidAddress &&
                                        item &&
                                        item.tokenowners_current &&
                                        item.tokenowners_current.length > 0 &&
                                        item.tokenowners_current.findIndex(
                                          (e) =>
                                            e.tokenOwner ===
                                              Wallet_Details.UserAccountAddr &&
                                            e.tokenOwner.toLowerCase() ===
                                              owneraddress.toLowerCase()
                                        ) > -1 && (
                                          <div className="infodos">
                                            {
                                              
                                              (
                                                <button
                                                  className="connectwallet mr-3"
                                                  onClick={() =>
                                                    PlaceABidForwardRef.current.AcceptBid_Select(
                                                      item,
                                                      curBid
                                                    )
                                                  }
                                                >
                                                  Accept
                                                </button>
                                              )
                                            }
                                          </div>
                                        )}

                                      {Wallet_Details.UserAccountAddr &&
                                        Wallet_Details.UserAccountAddr ===
                                          curBid.tokenBidAddress &&
                                        item &&
                                        item.tokenBid === true && (
                                          <button
                                            className="connectwallet mr-3"
                                            onClick={() =>
                                              PlaceABidForwardRef.current.CancelBid_Select(
                                                curBid
                                              )
                                            }
                                          >
                                            Cancel
                                          </button>
                                        )}
                                    </div>
                                  </div>
                                )}
                              </div>
                            );
                          })
                        : "No Bids Found"}
                    </div>

                  </div>
                </div>

                <div className="bidnowbtn">
                  {
                    (
                      (
                        (BuyOwnerDetailFirst.tokenOwner)?.toLowerCase() === (config.adminaddr)?.toLowerCase() &&
                        (
                          ( Number(freenftlimit) === 0 && Number(BuyOwnerDetailFirst.basePrice) !== 0) ||
                          (Number(BuyOwnerDetailFirst.tokenPrice) === 0) ||
                          (
                            Number(BuyOwnerDetailFirst.tokenPrice) > 0 &&
                            BuyOwnerDetailFirst.balance > 0
                          )
                        )
                      ) &&
                      owneraddress.toLowerCase() !== Wallet_Details.UserAccountAddr.toLowerCase() &&
                      Wallet_Details.UserAccountAddr !== ""
                    ) &&
                    <button
                      className="connectwallet wid_connectwallet mr-3 connect_wallet"
                      onClick={() =>
                        PurchaseNowForwardRef.current.PurchaseNow_Click(
                          item,
                          BuyOwnerDetailFirst,
                          WalletUserDetails,
                          owneraddress,
                          CurrentKeyUser
                        )
                      }
                    >
                      Buy
                    </button>
                  }

                  {MyTokenDetail &&
                  MyTokenDetail.tokenOwner === Wallet_Details.UserAccountAddr &&
                  MyTokenDetail.tokenPrice > 0 &&
                  owneraddress.toLowerCase() ===
                    String(Wallet_Details.UserAccountAddr).toLowerCase() ? (
                    <button
                      className="connectwallet mr-3"
                      onClick={() =>
                        CancelOrderForwardRef.current.CancelOrder_Click(
                          item,
                          token_owner_detail_first,
                          MyTokenDetail
                        )
                      }
                    >
                      Cancel Order
                    </button>
                  ) : (
                    MyTokenDetail.tokenOwner &&
                    item &&
                    MyTokenDetail.clocktime != null &&
                    MyTokenDetail.endclocktime != null &&
                    new Date(item.endclocktime) > Date.now() && (
                      <button className="bidbtn"></button>
                    )
                  )}
       
                 

                  {
                    
                    MyTokenDetail &&
                      MyTokenDetail?.tokenOwner?.toLowerCase() ===
                        Wallet_Details?.UserAccountAddr?.toLowerCase() &&
                      (MyTokenDetail.tokenPrice === null ||
                        MyTokenDetail.tokenPrice === "" ||
                        MyTokenDetail.tokenPrice === 0) &&
                      
                      (
                        <button
                          className="connectwallet mr-3"
                          onClick={() => {
                            PutOnSaleForwardRef.current.PutOnSale_Click(
                              item,
                              token_owner_detail_first,
                              MyTokenDetail,
                              suboutOfDateQuantity

                            );
                          }}
                        >
                          Put on Sale
                        </button>
                      )
                  }

                  {String(owneraddress)?.toLowerCase() !==
                  Wallet_Details.UserAccountAddr.toLowerCase() ? (
                    item &&
                    item.tokenowners_current &&
                    item.tokenowners_current.length > 0 &&
                    item.tokenowners_current[0].clocktime != null &&
                    item.tokenowners_current[0].endclocktime != null &&
                    new Date(item.tokenowners_current[0].endclocktime) <
                      Date.now() ? (
                      !isEmpty(Bids.highestBid) ? (
                        Bids &&
                        !isEmpty(Bids.highestBid) &&
                        Wallet_Details.UserAccountAddr &&
                        Wallet_Details.UserAccountAddr !==
                          Bids.highestBid.tokenBidAddress &&
                        item &&
                        item.tokenowners_current &&
                        item.tokenowners_current.findIndex(
                          (e) => e.tokenOwner === Wallet_Details.UserAccountAddr
                        ) > -1 &&
                        freenftlimit === 0 ? (
                          <button
                            className="bidbtn"
                            onClick={() =>
                              PlaceABidForwardRef.current.AcceptBid_Select(
                                item,
                                item.tokenowners_current[0],
                                Bids.highestBid
                              )
                            }
                          >
                            Accept......
                          </button>
                        ) : (
                          <button className="bidbtn">
                            Waiting owner to accept the bid
                          </button>
                        )
                      ) : (
                        <p className="bidbtn">Timed auction ended</p>
                      )
                    ) : 
                    MyTokenDetail.tokenOwner === undefined &&
                      AllowedQuantity > MyTokenBalance ? (
                      Bids && Bids.myBid && !Bids.myBid.status ? (
                       
                        <button
                          className="connectwallet wid_connectwallet mr-3"
                          onClick={() =>
                            PlaceABidForwardRef.current.PlaceABid_Click(
                              item,
                              item.tokenowners_current[0],
                              onwer_price,
                              Bids,
                              WalletUserDetails,
                              CurrentKeyUser
                            )
                          }
                        >
                          Bid
                        </button>
                      ) : (
                        Bids &&
                        Bids.myBid &&
                        Bids.myBid.status &&
                        (Bids.myBid.status === "pending" ? (
                          <button
                            className="connectwallet wid_connectwallet mr-3"
                            onClick={() =>
                              PlaceABidForwardRef.current.PlaceABid_Click(
                                item,
                                item.tokenowners_current[0],
                                onwer_price,
                                Bids,
                                WalletUserDetails,
                                CurrentKeyUser
                              )
                            }
                          >
                            Edit Bid
                          </button>
                        ) : (
                          Bids &&
                          Bids.myBid &&
                          Bids.myBid.status &&
                          Bids.myBid.status === "partiallyCompleted" && (
                            <p
                              className="buybtn mr-3"
                              onClick={() =>
                                PlaceABidForwardRef.current.CancelBid_Select(
                                  Bids.myBid
                                )
                              }
                            >
                              Cancel Bid
                            </p>
                          )
                        ))
                      )
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}
                </div>

            
              </div>
              <div className="col-lg-1 col-md-1 col-12"></div>
            </div>
          </div>
        </div>
        <Footer />
      </div>

    </>
  );
}
