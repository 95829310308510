import React, { useEffect, forwardRef, useImperativeHandle } from "react";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "bootstrap/dist/css/bootstrap.min.css";
import { useSelector } from "react-redux";
import Web3 from "web3";
import {
  PurchaseNow_Complete_Action,
  checkOtherPlatformDetais1155,
  getunlockdatas,
  getTodaySubAmt,
} from "../../actions/v1/token";
import { toast } from "react-toastify";
import DETH_ABI from "../../ABI/DETH_ABI.json";
import config from "../../lib/config";
import MULTIPLE from "../../ABI/MULTIPLE.json";
import isEmpty from "../../lib/isEmpty";
import tradeabi from "../../ABI/Trade.json";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import { getReceipt } from "../../actions/v1/getReceiptFunc";
import trade from "../../ABI/Trade.json";

toast.configure();
let toasterOption = config.toasterOption;

const exchangeAddress = config.exchangeAddress;
const contractAddr = config.smartContract;

export const Buynow = forwardRef((props, ref) => {
  const history = useHistory();

  const Wallet_Details = useSelector((state) => state.wallet_connect_context);
  const [item, Set_item] = React.useState(props.item);
  const [TokenPrice, Set_TokenPrice] = React.useState(0);
  const [BuyOwnerDetails, set_BuyOwnerDetail] = React.useState({});
  const [CoinName, set_CoinName] = React.useState(0);
  const [PurchaseBalance, Set_PurchaseBalance] = React.useState(0);
  const [NewTokenDecimal, setNewTokenDecimal] = React.useState(18);
  const [NewTokenAddress, setNewTokenAddresss] = React.useState(
    config.tokenAddress
  );
  const [NoOfToken, Set_NoOfToken] = React.useState("");
  const [YouWillPay, Set_YouWillPay] = React.useState(0);
  const [ApproveWei, Set_ApproveWei] = React.useState(0);
  const [MultipleWei, Set_MultipleWei] = React.useState(0);
  const [ValidateError, Set_ValidateError] = React.useState({});
  const [usdcal, setusdCal] = React.useState("");
  const [FormSubmitLoadings, Set_FormSubmitLoading] = React.useState("start");
  const [PurchaseCallStatus, setPurchaseCallStatus] = React.useState("init");
  const [ApproveCallStatus, setApproveCallStatus] = React.useState("init");
  const [ApprovalCallStatus, setApprovalCallStatus] = React.useState("init");
  const [subfee, setSubfee] = React.useState("");
  const [freenftlimit, set_freenftlimit] = React.useState("");
  const [buyemail, set_buyemail] = React.useState("");
  const [validemail, set_validemail] = React.useState(false);
  const [invalidemail, set_invalidemail] = React.useState(false);

  const [baseprice, set_baseprice] = React.useState("");
  const [WalletUserDetails, set_WalletUserDetails] = React.useState({});
  const [KeywordUser, set_KeywordUser] = React.useState(0);
  const [subscribePrice, set_subscribePrice] = React.useState(0);
  const [KeyUserCount, setKeyUserCount] = React.useState(0);
  const [currentOwner, setcurrentOwner] = React.useState(0);
  const [subpriccee, setsubpriccee] = React.useState(0);

  var { MyItemAccountAddr_Details } = props;

  useEffect(() => {
    Set_ValidateError({});
    cal();
  }, [NoOfToken, TokenPrice, Wallet_Details.currency_convertion]);

  const getkeywords = async (item) => {
    var geykeyword = await getunlockdatas({ category: item.tokenName });

    var keyboardUsers = geykeyword?.data?.getdailyDatas?.filter(
      (ite) => !isEmpty(ite?.Keywords)
    );
    set_KeywordUser(keyboardUsers.length);
  };

  const PriceCalculate = async (data = {}) => {
    try {
      const web3 = new Web3(Wallet_Details.providerss);
      const CoursetroContract = new web3.eth.Contract(trade, config.trade);
      const decimal = 18;
      var price = typeof data.price !== "undefined" ? data.price : TokenPrice;
      const quantity1 = data.quantity !== undefined ? data.quantity : NoOfToken;
      if (quantity1 > 0) {
        const quantity =
          quantity1 > freenftlimit ? quantity1 - freenftlimit : quantity1;

        if (
          quantity1 > freenftlimit &&
          BuyOwnerDetails.tokenOwner.toLowerCase() ===
            config.adminaddr.toLowerCase()
        ) {
          price = BuyOwnerDetails.basePrice;
          Set_TokenPrice(BuyOwnerDetails.basePrice);
        }
        const newPrice = quantity * (price * 1000000);
        if (subpriccee === 0) {
          var subamount = await getTodaySubAmt();
        } else {
          subamount = subpriccee;
        }
        setsubpriccee(subamount);
        if (subamount) {
          const subPrice = Number(subamount);

          const toMid = newPrice;
          const serfee =
            (toMid / 100000000) *
            (web3.utils.fromWei(String(Wallet_Details.Service_Fee_buyer)) *
              1000000);
          const totfee = serfee + toMid;

          const tot2cont = web3.utils.toWei(
            Number(totfee / 1000000).toFixed(18)
          );
          const dec = decimal === 18 ? 18 : 18 - decimal;
          const aprrove = tot2cont / 10 ** dec;

          set_subscribePrice(subPrice * quantity);
          setSubfee(subPrice);

          Set_YouWillPay(totfee / 1e6);
          Set_ApproveWei(aprrove);
          Set_MultipleWei(
            web3.utils.toWei(Number(totfee / 1000000).toFixed(18))
          );
        }
      }
    } catch (err) {
      console.error("Error in PriceCalculate:", err);
    }
  };

  async function BalanceCalculation(Coin) {
    if (Wallet_Details?.providerss && Coin) {
      var web3 = new Web3(Wallet_Details.providerss);
      if (web3) {
        var web3 = new Web3(Wallet_Details.providerss);
        if (Coin !== config.currencySymbol) {
          try {
            const MultiContract = new web3.eth.Contract(tradeabi, config.trade);

            var tokenAddress = await MultiContract.methods
              .getTokenAddress(Coin)
              .call();

            setNewTokenAddresss(tokenAddress);

            const TokenObj = new web3.eth.Contract(DETH_ABI, tokenAddress);

            var TokenBalance = Number(
              await TokenObj.methods
                .balanceOf(Wallet_Details.UserAccountAddr)
                .call()
            );

            var TokenDecimal = Number(await TokenObj.methods.decimals().call());
            Set_PurchaseBalance(TokenBalance / 10 ** TokenDecimal);

            setNewTokenDecimal(TokenDecimal);
          } catch (err) {}
        } else {
          Set_PurchaseBalance(Wallet_Details.UserAccountBal);
        }
      }
    }
  }

  async function FormSubmit_StepOne(e) {
    try {
      e.preventDefault();

      if (Wallet_Details.providerss) {
        var web3 = new Web3(Wallet_Details.providerss);
        if (web3) {
          var handle,
            receipt,
            CoursetroContract,
            contractCall,
            sendAmount,
            buyOwnerprice;
          var bidtoken =
            String(BuyOwnerDetails.CoinName) === config.currencySymbol
              ? "coin"
              : String(BuyOwnerDetails.CoinName);
          var subAmount = web3.utils.toWei(String(Number(subfee) * NoOfToken));

          var buyOwnerprice =
            BuyOwnerDetails.tokenOwner.toLowerCase() ===
            config.adminaddr.toLowerCase()
              ? Number(freenftlimit) === 0
                ? BuyOwnerDetails.basePrice * NoOfToken
                : BuyOwnerDetails.tokenPrice * NoOfToken
              : BuyOwnerDetails.tokenPrice * NoOfToken;
          var sendAmount1 = web3.utils.toWei(
            String(Number(Number(buyOwnerprice * 1e6)) / 1e6)
          );
          sendAmount =
            String(BuyOwnerDetails.CoinName) === config.currencySymbol
              ? MultipleWei
              : "0";

          try {
            setPurchaseCallStatus("processing");
            CoursetroContract = new web3.eth.Contract(trade, config.trade);

            var ids = [item.tokenCounts, sendAmount1, NoOfToken, subAmount];

            contractCall = await CoursetroContract.methods
              .saleToken(
                [bidtoken, config.tokenSymbol],
                BuyOwnerDetails.tokenOwner,
                ids
              )
              .send({
                from: Wallet_Details.UserAccountAddr,
                value: sendAmount,
              })
              .on("transactionHash", async (transactionHash) => {
                handle = setInterval(async () => {
                  receipt = await getReceipt(web3, transactionHash);
                  clr1();
                }, 8000);
              });
          } catch (error) {
            setPurchaseCallStatus("tryagain");
            toast.error("Order not placed", toasterOption);
          }

          async function clr1() {
            if (receipt !== null) {
              clearInterval(handle);
              if (receipt.status === true) {
                var date = new Date();
                var subgraceEndd = new Date(date.setDate(date.getDate() + 1));
                var postData = {
                  tokenOwner: BuyOwnerDetails.tokenOwner,
                  UserAccountAddr: Wallet_Details.UserAccountAddr,
                  tokenCounts: item.tokenCounts,
                  tokenType: item.type,
                  NoOfToken: NoOfToken,
                  CoinName: CoinName,
                  transactionHash: receipt.transactionHash,
                  tokenBid: true,
                  SubscribeEnd: subgraceEndd,
                  buyemail: buyemail,
                  subfeesAmtt: subfee,
                  subCoinName: config.tokenSymbol,
                  tokenName: item.tokenName,
                  currentOwnerDet: currentOwner,
                };

                var postPrice = {
                  tokenOwner: item.tokenowners_current.tokenOwner,
                  UserAccountAddr: Wallet_Details.UserAccountAddr,
                  tokenPrice: usdcal,
                };
                var Resp = await PurchaseNow_Complete_Action(postData);

                if (
                  Resp.data &&
                  Resp.data.toast &&
                  Resp.data.toast.type == "success"
                ) {
                  toast.success(
                    "Collectible purchase successfully",
                    toasterOption
                  );
                  setPurchaseCallStatus("done");
                  set_buyemail("");
                  window.$(".modal").modal("hide");
                  history.push("/");
                }
              }
            }
          }
        }
      } else {
        window.$("#connect_modal").modal("show");
      }
    } catch (err) {}
  }

  async function FormSubmit_StepTwo() {
    setPurchaseCallStatus("start");
    setApproveCallStatus("process");
    if (Wallet_Details.UserAccountAddr != null) {
      if (Wallet_Details.providerss) {
        var web3 = new Web3(Wallet_Details.providerss);
        if (web3) {
          var Approves, handle, receipt, sendVal;

          try {
            if (Wallet_Details?.SubscribeAmt != "") {
              var bidvalue = new web3.eth.Contract(DETH_ABI, NewTokenAddress);
              var subwitqty = Number(subfee) * Number(NoOfToken);

              var getAllowance = await bidvalue.methods
                .allowance(Wallet_Details.UserAccountAddr, config.trade)
                .call();
              if (NewTokenDecimal == 18) {
                var subAndAllowance = web3.utils.toWei(
                  String(
                    Number(web3.utils.fromWei(String(getAllowance))) + subwitqty
                  )
                );
                var PriceAllowanceAndSub = web3.utils.toWei(
                  String(
                    Number(ApproveWei) +
                      Number(web3.utils.fromWei(String(getAllowance))) +
                      subwitqty
                  )
                );

                var sendVal =
                  CoinName === config.currencySymbol
                    ? subAndAllowance
                    : PriceAllowanceAndSub;
              } else {
              }
            }
          } catch (err) {}
          try {
            if (Number(subwitqty) < Number(Wallet_Details?.Wen_Bln)) {
              await bidvalue.methods
                .approve(config.trade, String(sendVal))
                .send({
                  from: Wallet_Details.Accounts,
                })
                .on("transactionHash", async (transactionHash) => {
                  handle = setInterval(async () => {
                    receipt = await getReceipt(web3, transactionHash);
                    clr1();
                  }, 8000);
                });
            } else {
              setApproveCallStatus("try");

              toast.warn("your don't have enough balance");
            }
          } catch (e) {
            setApproveCallStatus("try");
          }
          async function clr1() {
            if (receipt != null) {
              clearInterval(handle);
              if (receipt.status === true) {
                setApproveCallStatus("done");
                setPurchaseCallStatus("init");
              }
            }
          }
        } else {
          window.$("#connect_modal").modal("show");
        }
      }
    } else {
      toast.warn("please connect your wallet");
    }
  }
  async function FormSubmit() {
    var validate = await ItemValidation({
      NoOfToken: NoOfToken,
      TokenPrice: TokenPrice,
    });

    if (isEmpty(validate)) {
      window.$("#buynow").modal("hide");
      window.$("#PurchaseStep_modal").modal("show");
    }
  }

  async function cal() {
    if (typeof Wallet_Details.currency_convertion === "object") {
      if (!isEmpty(item)) {
        var check = Wallet_Details?.Token_convertion?.filter(
          (items) => items.label == item.tokenowners_current.CoinName
        );
        var Pricecal =
          Wallet_Details.currency_convertion.currency ===
          item.tokenowners_current.CoinName
            ? Wallet_Details?.currency_convertion?.USD *
              item.tokenowners_current.tokenPrice *
              NoOfToken
            : check[0]?.USD * item.tokenowners_current.tokenPrice * NoOfToken;

        setusdCal(Pricecal);
      }
    }
  }

  const ItemValidation = async (data = {}) => {
    var ValidateError = {};

    var Chk_TokenPrice =
      typeof data.TokenPrice != "undefined" ? data.TokenPrice : TokenPrice;
    var quantity =
      typeof data.quantity != "undefined" ? data.quantity : NoOfToken;

    var Collectible_balance = 0;
    var balance =
      BuyOwnerDetails.tokenOwner?.toLocaleLowerCase() ===
      config.adminaddr?.toLowerCase()
        ? BuyOwnerDetails?.balance
        : BuyOwnerDetails?.quantity;

    if (buyemail !== "") {
      var emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

      if (!emailRegex.test(buyemail)) {
        ValidateError.email = "Please enter a valid email address";
        set_invalidemail("Please enter a valid email address.");
      } else if (buyemail === "") {
        ValidateError.email = "Email is required";

        set_invalidemail("Email is required");
      }
    }

    if (BuyOwnerDetails && BuyOwnerDetails.balance && balance) {
      Collectible_balance = BuyOwnerDetails.balance;
    }

    if (quantity === "") {
      ValidateError.NoOfToken = '"Quantity" is not allowed to be empty';
    } else if (quantity === 0) {
      ValidateError.NoOfToken = '"Quantity" must be greater than 0';
    } else if (isNaN(quantity) === true) {
      ValidateError.NoOfToken = '"Quantity" must be a number';
    }
    if (quantity > balance) {
      ValidateError.NoOfToken = '"Quantity" must be below on ' + balance;
    } else if (isNaN(Chk_TokenPrice) === true) {
      ValidateError.TokenPrice = '"Token Price" must be a number';
    } else if (
      String(BuyOwnerDetails.CoinName).toLowerCase() ===
      String(config.tokenSymbol).toLowerCase()
    ) {
      if (Wallet_Details.Wen_Bln === 0) {
        Set_FormSubmitLoading("error");
      } else if (Wallet_Details.Wen_Bln < Chk_TokenPrice) {
        Set_FormSubmitLoading("error");
      }
    } else if (
      String(BuyOwnerDetails.CoinName).toLowerCase() !==
      String(config.tokenSymbol).toLowerCase()
    ) {
      if (Wallet_Details.UserAccountBal === 0) {
        Set_FormSubmitLoading("errors");
      } else if (Wallet_Details.UserAccountBal < Chk_TokenPrice) {
        Set_FormSubmitLoading("errors");
      }
    } else {
      if (Chk_TokenPrice > Wallet_Details.UserAccountBal) {
        ValidateError.TokenPrice =
          "Insufficient balance, Check your wallet balance";
      } else {
        delete ValidateError.TokenPrice;
      }
    }

    if (!isEmpty(ValidateError)) {
      Set_FormSubmitLoading("errors1");
    }
    if (NoOfToken > 0) {
      Set_FormSubmitLoading("start");
    }
    Set_ValidateError(ValidateError);
    return ValidateError;
  };

  async function orderApprovecheck(item) {
    if (Wallet_Details.providerss == null) {
      toast.warning("OOPS!..connect Your Wallet", toasterOption);
      return false;
    }
    var web3 = new Web3(Wallet_Details.providerss);
    try {
      var MultiContract = new web3.eth.Contract(MULTIPLE, item.contractAddress);
      var status = await MultiContract.methods
        .isApprovedForAll(Wallet_Details.UserAccountAddr, config.trade)
        .call();
      return status;
    } catch (e) {
      return false;
    }
  }

  const inputChange = (e) => {
    Set_FormSubmitLoading("start");

    if (e.target.name === "Quantity") {
      PriceCalculate({ quantity: parseInt(e.target.value) });
      Set_NoOfToken(parseInt(e.target.value));
    } else {
      set_buyemail(e.target.value);
      Set_FormSubmitLoading("start");
    }
  };

  useImperativeHandle(ref, () => ({
    async PurchaseNow_Click(
      item,
      BuyOwnerDetail = {},
      WalletUserDetails,
      owneraddress,
      CurrentKeyUser
    ) {
      if (Wallet_Details.UserAccountAddr !== "") {
        setKeyUserCount(CurrentKeyUser);

        var web3 = new Web3(Wallet_Details.providerss);
        set_WalletUserDetails(WalletUserDetails);
        set_buyemail(WalletUserDetails.email);
        if (BuyOwnerDetail && typeof BuyOwnerDetail.tokenOwner != "undefined") {
          item.tokenowners_current = {};
          item.tokenowners_current = BuyOwnerDetail;
        }

        if (item && web3) {
          var balance = await checkOtherPlatformDetais1155(
            item,
            BuyOwnerDetail,
            item.type,
            web3
          );

          if (Number(balance) < Number(BuyOwnerDetail.balance)) {
            toast.warning("Nft purchase limit exceed ", toasterOption);
            setTimeout(() => {
              history.push("/my-items");
            }, 1000);
            return false;
          } else {
            var freenft = 0;
            var baseamt = 0;
            var NftPrices = 0;
            var pricc = 0;
            Set_item(item);
            getkeywords(item);

            item?.tokenowners_all?.forEach((itemm) => {
              const adminAddress = config.adminaddr.toLowerCase();
              if (itemm.tokenOwner.toLowerCase() === adminAddress) {
                set_baseprice(itemm.basePrice);
                baseamt = itemm.basePrice;
              }
            });

            var currentOwner =
              item.tokenowners_all.length > 0 &&
              item.tokenowners_all.filter(
                (ite) =>
                  ite.tokenOwner.toLowerCase() === owneraddress.toLowerCase()
              );

            setcurrentOwner(currentOwner.length > 0 ? currentOwner[0] : []);

            set_freenftlimit(Number(currentOwner[0].freeNft));
            freenft = Number(currentOwner[0].freeNft);

            if (BuyOwnerDetail && BuyOwnerDetail !== "") {
              set_BuyOwnerDetail(BuyOwnerDetail);
              if (freenft > 0) {
                Set_TokenPrice(0);
                NftPrices = 0;
              } else {
                const price =
                  BuyOwnerDetail.tokenPrice === 0
                    ? BuyOwnerDetail.basePrice
                    : BuyOwnerDetail.tokenPrice;
                Set_TokenPrice(price);
                NftPrices = price;
              }
            }

            if (BuyOwnerDetail && BuyOwnerDetail.CoinName) {
              set_CoinName(BuyOwnerDetail.CoinName);
              BalanceCalculation(BuyOwnerDetail.CoinName);
            }

            PriceCalculate({
              quantity: NoOfToken,
              price: NftPrices,
              tokenRoyality: item.tokenRoyality,
              counts: item.tokenCounts,
            });

            var check = await orderApprovecheck(item);
            if (check) {
              setApprovalCallStatus("done");
            }

            window.$("#buynow").modal("show");
          }
        }
      } else {
        window.$("#connect_modal").modal("show");
        toast.warning("OOPS!..connect Your Wallet");
      }
    },
  }));

  return (
    <>
      <div
        className="modal fade"
        id="buynow"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="buynowLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="buynowLabel">
                Checkout
              </h5>
            </div>
            <div className="modal-body p-4">
              <div className="buynow_popup">
                <div className="buynow_popupname">
                  <h6>Seller</h6>
                  <h2>
                    {item.userprofile && item.userprofile.name
                      ? item.userprofile && item.userprofile.name
                      : String(
                          BuyOwnerDetails &&
                            (BuyOwnerDetails.tokenOwner !== ""
                              ? BuyOwnerDetails.tokenOwner
                              : item.tokenOwner)
                        )
                          .slice(0, 6)
                          .concat("....")}
                  </h2>
                </div>
                <div className="buynow_popupname1">
                  <h6>Buyer</h6>
                  <h2>
                    {!isEmpty(MyItemAccountAddr_Details) &&
                    MyItemAccountAddr_Details.name !== ""
                      ? MyItemAccountAddr_Details.name
                      : String(Wallet_Details.UserAccountAddr)
                          .slice(0, 6)
                          .concat("....")}
                  </h2>
                </div>
              </div>

              {
                <div className="mb-3">
                  <label htmlFor="qty">
                    Quantity
                    <span>
                      (
                      {BuyOwnerDetails.tokenOwner?.toLocaleLowerCase() ===
                      config.adminaddr?.toLowerCase()
                        ? BuyOwnerDetails?.balance
                        : BuyOwnerDetails?.quantity}{" "}
                      is available)
                    </span>
                  </label>

                  <div className="mb-3 input_grp_style_1">
                    <input
                      type="number"
                      step="0.01"
                      class="form-control"
                      name="Quantity"
                      id="Quantity"
                      value={NoOfToken}
                      onWheel={(e) => e.target.blur()}
                      onChange={(e) => inputChange(e)}
                      placeholder="Enter the Quantity"
                    />
                    {ValidateError.NoOfToken && (
                      <span className="text-danger">
                        {ValidateError.NoOfToken}
                      </span>
                    )}
                  </div>
                </div>
              }

              {
                <div className="mb-3">
                  <div className="mb-3 input_grp_style_1">
                    <p className="email_label_pop requ_title">
                      Enter email *Required*{" "}
                    </p>

                    <input
                      type="text"
                      class="form-control"
                      name="buyemail"
                      id="buyemail"
                      value={buyemail}
                      onChange={inputChange}
                      placeholder="Enter your email"
                    />
                    {validemail ? (
                      <span className="text-success"></span>
                    ) : (
                      invalidemail && (
                        <span className="text-danger">{invalidemail}</span>
                      )
                    )}
                  </div>
                </div>
              }

              <div className="buynow_popup mb-2">
                <div className="buynow_popupname">
                  <h6>Token Price</h6>
                </div>
                <div className="buynow_popupname1">
                  <h6>
                    {Number(NoOfToken) <= Number(freenftlimit)
                      ? freenftlimit > 0
                        ? 0
                        : freenftlimit === 0 && TokenPrice !== 0
                        ? TokenPrice
                        : BuyOwnerDetails.basePrice
                      : BuyOwnerDetails.basePrice}

                    {BuyOwnerDetails.CoinName}
                  </h6>
                </div>
              </div>

              <div className="buynow_popup mb-2">
                <div className="buynow_popupname">
                  <h6>Wallet Balance</h6>
                </div>
                <div className="buynow_popupname1">
                  <h6>{Number(Wallet_Details?.UserAccountBal).toFixed(4)}</h6>
                  <h6>{config.currencySymbol}</h6>
                </div>
              </div>
              {String(BuyOwnerDetails.CoinName).toLowerCase() ===
                String(CoinName).toLowerCase() && (
                <div className="buynow_popup mb-2">
                  <div className="buynow_popupname">
                    <h6>Token Balance</h6>
                  </div>
                  <div className="buynow_popupname1">
                    <h6>
                      {" "}
                      {String(Wallet_Details.Wen_Bln)} {config.tokenSymbol}
                    </h6>
                  </div>
                </div>
              )}
              <div className="buynow_popup mb-2">
                <div className="buynow_popupname">
                  <h6>Service Fee</h6>
                </div>
                <div className="buynow_popupname1">
                  <h6>
                    {((Number(Wallet_Details.Service_Fee_buyer) /
                      Number(config.decimalvalues)) *
                      TokenPrice) /
                      100}
                    <h6>{CoinName ? CoinName : ""}</h6>
                  </h6>
                </div>
              </div>
              <div className="buynow_popup mb-2">
                <div className="buynow_popupname">
                  <h6>Total Daily Active User </h6>
                </div>
                <div className="buynow_popupname1">
                  <h6>{subscribePrice + " " + config.tokenSymbol}</h6>
                </div>
              </div>

              <div className="buynow_popup mb-2">
                <div className="buynow_popupname">
                  <h6>Fee per daily active user</h6>
                </div>
                <div className="buynow_popupname1">
                  <h6>
                    {" "}
                    {subscribePrice.toFixed(5) + " " + config.tokenSymbol}
                  </h6>
                </div>
              </div>

              <div className="buynow_popup mb-2">
                <div className="buynow_popupname">
                  <h6>Transaction Total</h6>
                </div>
                <div className="buynow_popupname1">
                  <h6>
                    {" "}
                    {YouWillPay}
                    {" " + BuyOwnerDetails.CoinName}
                  </h6>
                </div>
              </div>

              <div className="buynow_popup mb-2">
                <div className="buynow_popupname">
                  <h6>Total Daily Active User</h6>
                </div>
                <div className="buynow_popupname1">
                  <h6>{KeyUserCount}</h6>
                </div>
              </div>
            </div>

            <div className="modal-footer">
              <button
                className="buybtn"
                onClick={
                  (FormSubmitLoadings === "start" || isEmpty(ValidateError)) &&
                  (() => FormSubmit())
                }
                disabled={
                  FormSubmitLoadings === "processing" ||
                  FormSubmitLoadings === "error" ||
                  FormSubmitLoadings === "errors" ||
                  FormSubmitLoadings === "errors1" ||
                  !isEmpty(ValidateError)
                }
              >
                {FormSubmitLoadings === "processing" && (
                  <>
                    {" "}
                    <i
                      className="fa fa-spinner mr-3 spinner_icon"
                      aria-hidden="true"
                      id="circle1"
                    ></i>{" "}
                    <>In-Progress</>
                  </>
                )}
                {FormSubmitLoadings === "error" && "Check Wenlambo Balance"}
                {FormSubmitLoadings === "start" && "Proceed to payment"}
                {FormSubmitLoadings === "errors" && "Check Balance"}
                {FormSubmitLoadings === "errors1" && "Error in Field"}
              </button>
              <button className="bidbtn" data-bs-dismiss="modal">
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="PurchaseStep_modal"
        data-bs-backdrop="static"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="create_item_modalCenteredLabel"
        aria-hidden="true"
        data-keyboard="false"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-sm"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header text-center">
              <h5 className="modal-title" id="PurchaseStepLabel">
                Follow Steps
              </h5>
            </div>

            <div className="modal-body">
              <form>
                {
                  <div className="text-center">
                    <p className="mt-3 purchase_desc text-center">
                      Review transaction
                    </p>
                    <button
                      type="button"
                      onClick={() => FormSubmit_StepTwo()}
                      className="buybtn"
                      disabled={
                        ApproveCallStatus === "process" ||
                        ApproveCallStatus === "done"
                      }
                    >
                      {ApproveCallStatus === "process" && (
                        <i
                          className="fa fa-spinner mr-3 spinner_icon"
                          aria-hidden="true"
                          id="circle1"
                        ></i>
                      )}
                      {ApproveCallStatus === "init" && "Approve"}
                      {ApproveCallStatus === "process" && "In-progress..."}
                      {ApproveCallStatus === "done" && "Done"}
                      {ApproveCallStatus === "try" && "Try Again"}
                    </button>
                  </div>
                }
                <div className="text-center my-3">
                  <p className="mt-3 purchase_desc text-center">
                    Make transaction with your wallet
                  </p>
                  <button
                    type="button"
                    onClick={(e) => FormSubmit_StepOne(e)}
                    className={"buybtn"}
                    disabled={
                      String(CoinName) !== config.currencySymbol
                        ? ApproveCallStatus === "init" ||
                          ApproveCallStatus === "process"
                        : true &&
                          (PurchaseCallStatus === "processing" ||
                            PurchaseCallStatus === "start" ||
                            PurchaseCallStatus === "done")
                    }
                  >
                    {PurchaseCallStatus === "processing" && (
                      <i
                        className="fa fa-spinner mr-3 spinner_icon"
                        aria-hidden="true"
                        id="circle1"
                      ></i>
                    )}
                    {PurchaseCallStatus === "init" && "Purchase"}
                    {PurchaseCallStatus === "start" && "Purchase"}

                    {PurchaseCallStatus === "processing" && "In-progress..."}
                    {PurchaseCallStatus === "done" && "Done"}
                    {PurchaseCallStatus === "tryagain" && "Try Again"}
                  </button>
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <Link className="bidbtn" data-bs-dismiss="modal">
                Cancel
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
});
